export default {
    namespaced: true,
    state: {
        is_close_menu : false,
        is_expanded : false,
        is_option_setting : false,
        is_option_ef : false
    },
    mutations: {
        setStatusOpenCloseMenu : (state, payload) => {
            state.is_close_menu = payload;
        },
        setIsExpanded : (state,payload) => {
            state.is_expanded = payload;
        },
        setIsOptionSetting : (state,payload) => {
            state.is_option_setting = payload;
        },
        setIsOptionEf : (state,payload) => {
            state.is_option_ef = payload;
        },
    },
    actions: {
        setStatusCloseMenu : (state, payload) => {
            state.commit("setStatusOpenCloseMenu", payload);
        },
        setIsExpanded : (state, payload) => {
            state.commit("setIsExpanded", payload);
        },
        setIsOptionSetting : (state, payload) => {
            state.commit("setIsOptionSetting", payload);
        },
        setIsOptionEf : (state, payload) => {
            state.commit("setIsOptionEf", payload);
        },
    },
    getters: {
        statusCloseMenu: (state) => state.is_close_menu,
    },
}