export default {
    namespaced: true,
    state: {
        data: null,
        registerCode: null
    },
    mutations: {
        setFormNameRegister : (state, payload) => {
            state.data = payload
        },
        setRegisterCode :  (state, payload) => {
            state.registerCode = payload
        },
    },
    actions: {
        setFormNameRegister : (state, payload) => {
            state.commit("setFormNameRegister", payload);
        },
        setRegisterCode : (state, payload) => {
            state.commit("setRegisterCode", payload);
        }
    },
    getters: {
        registerData: (state) => state.data,
    },
}