var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.componentType,{tag:"component",class:_vm.classes,style:({
    color: _vm.color,
    'white-space': _vm.ellipsis ? 'nowrap' : 'normal',
    'word-break': _vm.disbreak ? 'normal' : 'break-word',
    overflow: _vm.ellipsis ? 'hidden' : 'visible',
    'text-overflow': _vm.ellipsis ? 'ellipsis' : '',
  })},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }