<template>
  <div>
    <b-modal
      id="modal-form-change-password"
      title=""
      ok-title="Save"
      cancel-title="Cancel"
      size=""
      centered
      @hidden="reset()"
    >
      <template #modal-header="{}">
        <div class="fw-bold text-bgreen-100 fs-3">
          {{ $t("TXT_CHANGE_PASSWORD") }}
        </div>
        <i class="fa-solid fa-xmark fs-5 cursor-pointer" @click="closeModal"></i>
      </template>
      <div class="p-2 pt-0">
        <form>
          <div class="row m-0">
            <div class="col-12 p-2">
              <label class="mb-1 text-dark"
                >{{ $t("LB_OLD_PASSWORD") }}<span style="color: red">*</span></label
              >
              <div class="form-group m-0">
                <b-form-input
                  v-model="form_data.odl_password"
                  id=""
                  :type="!is_show_odl_password ? 'password' : 'text'"
                  :placeholder="$t('F_OLD_PASSWORD')"
                  required
                >
                </b-form-input>
                <svg
                  v-if="is_show_odl_password"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="currentColor"
                  class="bi bi-eye me-2 cursor-pointer"
                  style="float: right; margin-top: -28px; position: relative; z-index: 2"
                  viewBox="0 0 16 16"
                  @click="is_show_odl_password = !is_show_odl_password"
                >
                  <path
                    d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                  />
                  <path
                    d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                  />
                </svg>
                <svg
                  v-if="!is_show_odl_password"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="currentColor"
                  class="bi bi-eye-slash me-2 cursor-pointer"
                  style="float: right; margin-top: -28px; position: relative; z-index: 2"
                  viewBox="0 0 16 16"
                  @click="is_show_odl_password = !is_show_odl_password"
                >
                  <path
                    d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
                  />
                  <path
                    d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
                  />
                  <path
                    d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"
                  />
                </svg>
              </div>
              <span
                v-if="!is_validate && v$.form_data.odl_password.$error"
                class="error-text"
                >{{ $t("VA_PLEASE_ENTER_OLD_PASSWORD") }}</span
              >
            </div>
            <div class="col-12 p-2">
              <label class="mb-1 text-dark"
                >{{ $t("LB_NEW_PASSWORD") }}<span style="color: red">*</span></label
              >
              <div class="form-group m-0">
                <b-form-input
                  v-model="form_data.new_password"
                  id=""
                  :type="!is_show_new_password ? 'password' : 'text'"
                  @input="checkPassword"
                  :placeholder="$t('F_NEW_PASSWORD')"
                  required
                >
                </b-form-input>
                <svg
                  v-if="is_show_new_password"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="currentColor"
                  class="bi bi-eye me-2 cursor-pointer"
                  style="float: right; margin-top: -28px; position: relative; z-index: 2"
                  viewBox="0 0 16 16"
                  @click="is_show_new_password = !is_show_new_password"
                >
                  <path
                    d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                  />
                  <path
                    d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                  />
                </svg>
                <svg
                  v-if="!is_show_new_password"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="currentColor"
                  class="bi bi-eye-slash me-2 cursor-pointer"
                  style="float: right; margin-top: -28px; position: relative; z-index: 2"
                  viewBox="0 0 16 16"
                  @click="is_show_new_password = !is_show_new_password"
                >
                  <path
                    d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
                  />
                  <path
                    d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
                  />
                  <path
                    d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"
                  />
                </svg>
              </div>
              <span
                class="error-text"
                v-if="!is_validate && !v$.form_data.new_password.required.$response"
                >{{ $t("VA_PLEASE_ENTER_NEW_PASSWORD") }}</span
              >
              <span class="error-text" v-else-if="!is_validate && !valid_password">{{
                $t("VA_PLEASE_ENTER_CORRECT_PASS_FORM")
              }}</span>
            </div>
            <div class="col-12 p-2">
              <label class="mb-1 text-dark"
                >{{ $t("TXT_CONFIRM_PASSWORD") }}<span style="color: red">*</span></label
              >
              <div class="form-group m-0">
                <b-form-input
                  v-model="form_data.confirm_new_password"
                  id=""
                  :type="!is_show_confirm_password ? 'password' : 'text'"
                  @input="checkConfirmPassword()"
                  :placeholder="$t('F_CONFIRM_PASSWORD')"
                  required
                >
                </b-form-input>
                <svg
                  v-if="is_show_confirm_password"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="currentColor"
                  class="bi bi-eye me-2 cursor-pointer"
                  style="float: right; margin-top: -28px; position: relative; z-index: 2"
                  viewBox="0 0 16 16"
                  @click="is_show_confirm_password = !is_show_confirm_password"
                >
                  <path
                    d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                  />
                  <path
                    d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                  />
                </svg>
                <svg
                  v-if="!is_show_confirm_password"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="currentColor"
                  class="bi bi-eye-slash me-2 cursor-pointer"
                  style="float: right; margin-top: -28px; position: relative; z-index: 2"
                  viewBox="0 0 16 16"
                  @click="is_show_confirm_password = !is_show_confirm_password"
                >
                  <path
                    d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
                  />
                  <path
                    d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
                  />
                  <path
                    d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"
                  />
                </svg>
              </div>
              <span
                class="error-text"
                v-if="
                  !is_validate && !v$.form_data.confirm_new_password.required.$response
                "
                >{{ $t("VA_PLEASE_ENTER_YOUR_CONFIRM_PASS") }}</span
              >
              <span
                class="error-text"
                v-else-if="
                  !is_validate &&
                  !v$.form_data.confirm_new_password.sameAsPassword.$response
                "
                >{{ $t("VA_ENTER_CORRECT_PASSWORD_CONFIRMATION") }}</span
              >
            </div>
            <div class="mt-3 ps-3">
              <div class="row mb-4" style="font-size: 0.8rem">
                <span
                  v-bind:class="{ is_valid: contains_eight_characters }"
                  class="col-6 py-2"
                >
                  <img
                    v-if="contains_eight_characters"
                    src="@/assets/register/checkmark.svg"
                    width="14px"
                    alt="check"
                  />
                  <img
                    v-else
                    src="@/assets/register/checkemp.svg"
                    width="14px"
                    alt="check"
                  />
                  {{ $t("TXT_8_CHARACTERS") }}
                </span>
                <span v-bind:class="{ is_valid: contains_number }" class="col-6 py-2">
                  <img
                    v-if="contains_number"
                    src="@/assets/register/checkmark.svg"
                    width="14px"
                    alt="check"
                  />
                  <img
                    v-else
                    src="@/assets/register/checkemp.svg"
                    width="14px"
                    alt="check"
                  />
                  {{ $t("TXT_CONTAINS_NUMBER") }}
                </span>
                <span v-bind:class="{ is_valid: contains_uppercase }" class="col-6 py-2">
                  <img
                    v-if="contains_uppercase"
                    src="@/assets/register/checkmark.svg"
                    width="14px"
                    alt="check"
                  />
                  <img
                    v-else
                    src="@/assets/register/checkemp.svg"
                    width="14px"
                    alt="check"
                  />
                  {{ $t("TXT_CONTAINS_UPPERCASE") }}
                </span>
                <span v-bind:class="{ is_valid: contains_lowercase }" class="col-6 py-2">
                  <img
                    v-if="contains_lowercase"
                    src="@/assets/register/checkmark.svg"
                    width="14px"
                    alt="check"
                  />
                  <img
                    v-else
                    src="@/assets/register/checkemp.svg"
                    width="14px"
                    alt="check"
                  />
                  {{ $t("TXT_CONTAINS_LOWERCASE") }}
                </span>
                <span
                  v-bind:class="{ is_valid: contains_special_character }"
                  class="col-6 py-2"
                >
                  <img
                    v-if="contains_special_character"
                    src="@/assets/register/checkmark.svg"
                    width="14px"
                    alt="check"
                  />
                  <img
                    v-else
                    src="@/assets/register/checkemp.svg"
                    width="14px"
                    alt="check"
                  />
                  {{ $t("TXT_CONTAINS_SPECIAL_CHARACTER") }}
                </span>
                <span v-bind:class="{ is_valid: confirm_password }" class="col-6 py-2">
                  <img
                    v-if="confirm_password"
                    src="@/assets/register/checkmark.svg"
                    width="14px"
                    alt="check"
                  />
                  <img
                    v-else
                    src="@/assets/register/checkemp.svg"
                    width="14px"
                    alt="check"
                  />
                  {{ $t("TXT_CONFIRM_PASSWORD") }}
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
      <template #modal-footer="{}">
        <button class="btn button-green px-5" @click="openConfirmLogout()">
          {{ $t("BTN_SAVE") }}
        </button>
      </template>
    </b-modal>
    <ModalConfirmChangePasswordVue
      :header="$t('TXT_HEADER_CONFIRM_PASSWORD_CHANGE')"
      :text="$t('TXT_SUB_CONFIRM_PASSWORD_CHANGE')"
      textColor=""
      @onConfirm="onSave()"
    />
  </div>
</template>
<script>
import { httpClient } from "@/services/axiosInstance.js";
import { apiURL } from "@/configs/serverConfig";
import { required, sameAs } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import ModalConfirmChangePasswordVue from "./ModalConfirmChangePassword.vue";
export default {
  props: ["userType"],
  components: {
    ModalConfirmChangePasswordVue,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      form_data: {
        odl_password: null,
        new_password: null,
        confirm_new_password: null,
      },
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_lowercase: false,
      contains_special_character: false,
      confirm_password: false,
      valid_password: false,
      is_validate: true, //chack validete
      isValidate: false, //chack disble btn save
      is_show_odl_password: false,
      is_show_new_password: false,
      is_show_confirm_password: false,
    };
  },
  validations() {
    return {
      form_data: {
        odl_password: { required },
        new_password: { required },
        confirm_new_password: {
          required,
          sameAsPassword: sameAs(this.form_data.new_password),
        },
      },
    };
  },
  methods: {
    reset() {
      this.form_data.odl_password = null;
      this.form_data.new_password = null;
      this.form_data.confirm_new_password = null;
      this.password_length = 0;
      this.contains_eight_characters = false;
      this.contains_number = false;
      this.contains_uppercase = false;
      this.contains_lowercase = false;
      this.contains_special_character = false;
      this.confirm_password = false;
      this.valid_password = false;
      this.is_validate = true;
      this.isValidate = false;
      this.is_show_odl_password = false;
      this.is_show_new_password = false;
      this.is_show_confirm_password = false;
    },
    async onSave() {
      if (this.isValidate) {
        const url = `${apiURL}/user/change_password`;
        const data = {
          current_password: this.form_data.odl_password,
          new_password: this.form_data.new_password,
        };
        try {
          const res_password = await httpClient.post(url, data);
          if (res_password.status === 200) {
            // console.log("res_password", res_password);
            this.$toast.success(this.$t("TXT_SAVE_DONE"));
            this.logout();
          }
        } catch (err) {
          this.$toast.error({
            component: "styled-toast",
            props: {
              type: "error",
              message: err.response.data.message,
            },
          });
        }
      } else {
        // console.log("isValidate", this.isValidate);
        this.is_validate = false;
      }
    },
    async openConfirmLogout() {
      this.isValidate = await this.v$.$validate();
      if (this.isValidate) {
        this.$bvModal.show("modal-confirm-change-password");
      } else {
        this.is_validate = false;
      }
    },
    async logout() {
      this.$store.commit("setToken", "");
      this.$store.commit("setRoleAction", null);
      if (this.userType == 2 || this.userType == 3) {
        //auditor
        this.$router.push("/login-auditor");
      } else {
        this.$router.push("/");
      }
      // this.$router.go();
    },
    closeModal() {
      this.$bvModal.hide("modal-form-change-password");
    },
    checkPassword() {
      this.password_length = this.form_data.new_password.length;
      //eslint-disable-next-line
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

      if (this.password_length >= 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }

      this.contains_number = /\d/.test(this.form_data.new_password);
      this.contains_lowercase = /[a-z]/.test(this.form_data.new_password);
      this.contains_uppercase = /[A-Z]/.test(this.form_data.new_password);
      this.contains_special_character = format.test(this.form_data.new_password);

      if (
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true &&
        this.contains_lowercase == true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    },
    checkConfirmPassword() {
      if (this.form_data.new_password) {
        if (this.form_data.new_password === this.form_data.confirm_new_password) {
          this.confirm_password = true;
        } else {
          this.confirm_password = false;
        }
      } else {
        this.confirm_password = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
