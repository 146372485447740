<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width || '25'"
    :height="height || '24'"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M12.4165 7V3H2.4165V21H22.4165V7H12.4165ZM6.4165 19H4.4165V17H6.4165V19ZM6.4165 15H4.4165V13H6.4165V15ZM6.4165 11H4.4165V9H6.4165V11ZM6.4165 7H4.4165V5H6.4165V7ZM10.4165 19H8.4165V17H10.4165V19ZM10.4165 15H8.4165V13H10.4165V15ZM10.4165 11H8.4165V9H10.4165V11ZM10.4165 7H8.4165V5H10.4165V7ZM20.4165 19H12.4165V17H14.4165V15H12.4165V13H14.4165V11H12.4165V9H20.4165V19ZM18.4165 11H16.4165V13H18.4165V11ZM18.4165 15H16.4165V17H18.4165V15Z"
      fill="#212121"
    />
  </svg>
</template>
<script>
export default {
  props: ["width", "height"],
};
</script>
