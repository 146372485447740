<template>
  <div
    @click="$emit('on-click')"
    style="
      float: right;
      margin-top: -28px;
      position: relative;
      z-index: 2;
      padding-right: 5px;
    "
  >
    <svg
      v-if="opened"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
        fill="#212121"
      />
    </svg>
    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M12 6.20757C15.79 6.20757 19.17 8.43212 20.82 11.9517C20.23 13.2259 19.4 14.3225 18.41 15.2102L19.82 16.6828C21.21 15.3982 22.31 13.7898 23 11.9517C21.27 7.36684 17 4.1188 12 4.1188C10.73 4.1188 9.51 4.32768 8.36 4.7141L10.01 6.43734C10.66 6.30157 11.32 6.20757 12 6.20757ZM10.93 7.39817L13 9.56005C13.57 9.82115 14.03 10.3016 14.28 10.8969L16.35 13.0587C16.43 12.7037 16.49 12.3277 16.49 11.9413C16.5 9.35118 14.48 7.25196 12 7.25196C11.63 7.25196 11.28 7.30418 10.93 7.39817ZM2.01 3.98303L4.69 6.78199C3.06 8.1188 1.77 9.89426 1 11.9517C2.73 16.5366 7 19.7846 12 19.7846C13.52 19.7846 14.98 19.4817 16.32 18.9282L19.74 22.5L21.15 21.0274L3.42 2.5L2.01 3.98303ZM9.51 11.8159L12.12 14.5418C12.08 14.5522 12.04 14.5627 12 14.5627C10.62 14.5627 9.5 13.393 9.5 11.9517C9.5 11.8995 9.51 11.8681 9.51 11.8159ZM6.11 8.26501L7.86 10.0927C7.63 10.6671 7.5 11.2937 7.5 11.9517C7.5 14.5418 9.52 16.6514 12 16.6514C12.63 16.6514 13.23 16.5157 13.77 16.2755L14.75 17.299C13.87 17.5496 12.95 17.6958 12 17.6958C8.21 17.6958 4.83 15.4713 3.18 11.9517C3.88 10.4582 4.9 9.22585 6.11 8.26501Z"
        fill="#212121"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: ["opened"],
};
</script>