export default {
    namespaced: true,
    state: {
        status_verify: null,
    },
    mutations: {
        setStatusVerify: (state, payload) => {
            state.status_verify = payload
        },
    },
    actions: {
        setStatusVerify : (state, payload) => {
            state.commit("setStatusVerify", payload);
        },
    },
    getters: {
        statusVerify: (state) => state.status_verify,
    },
}