<template>
  <div>
    <!-- <ModalStepsForUse :visible="visible" @handleNavigate="handleNavigate" /> -->
    <div>
      <Navbar @toggleMenu="toggleMenu" />
      <div class="app-side-bar" style="background-color: var(--tretiary-grey_10)">
        <SideBar :toggleMenu="is_expanded" ref="sidebarManu" />
        <div class="main-content pt-0 overflow-y-auto" :class="checkOverflow()">
          <div v-if="!fixed_header" class="position-header1">
            <!-- header -->
            <div style="overflow-x: hidden; overflow-y: hidden">
              <div
                class="bg-get-start px-4 my-auto d-flex align-middle"
                style="height: 30px"
              >
                <b-breadcrumb class="my-auto">
                  <b-breadcrumb-item :href="$route.matched[0].path">
                    {{ $t("TXT_HOME") }}
                  </b-breadcrumb-item>
                  <span class="mx-2">/</span>
                </b-breadcrumb>
                <b-breadcrumb
                  v-for="(matched, idx) in $route.matched"
                  :key="idx"
                  class="my-auto"
                >
                  <b-breadcrumb-item
                    :href="matched.path"
                    :active="$route.name == matched.name"
                  >
                    {{ $t(`${matched.meta.key}`) }}
                  </b-breadcrumb-item>
                  <span class="mx-2" v-if="idx != Object.keys($route.matched).length - 1">
                    /
                  </span>
                </b-breadcrumb>
              </div>
              <slot name="header"></slot>
            </div>
          </div>

          <div v-if="fixed_header" class="position-header2 bg-white" ref="headerSlot">
            <!-- header -->
            <div style="overflow-x: hidden">
              <div
                class="bg-get-start py-2 px-4 my-auto d-flex align-middle"
                style="height: 30px"
              >
                <b-breadcrumb class="my-auto">
                  <b-breadcrumb-item :href="$route.matched[0].path">
                    <span style="color: var(--tretiary-sky_blue_100)">
                      {{ $t("TXT_HOME") }}
                    </span>
                  </b-breadcrumb-item>
                  <span class="mx-2">/</span>
                </b-breadcrumb>
                <b-breadcrumb
                  v-for="(matched, idx) in $route.matched"
                  :key="idx"
                  class="my-auto"
                >
                  <b-breadcrumb-item
                    :href="matched.path"
                    :active="$route.name == matched.name"
                  >
                    {{ $t(`${matched.meta.key}`) }}
                  </b-breadcrumb-item>
                  <span class="mx-2" v-if="idx != Object.keys($route.matched).length - 1">
                    /
                  </span>
                </b-breadcrumb>
              </div>
            </div>
            <div style="overflow-x: hidden">
              <slot name="header"></slot>
            </div>
          </div>
          <!-- body -->
          <div class="z-0">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar/NavbarHeader.vue";
import SideBar from "@/components/Sidebar/SidebarMenu.vue";
// import ModalStepsForUse from "@/components/Modal/ModalStepsForUse.vue";

export default {
  components: {
    Navbar,
    SideBar,
    // ModalStepsForUse,
  },
  data() {
    return {
      // visible: false,
      is_expanded: this.$store.state.menu.is_expanded,
      colToggle: true,
      active: false,
      fixed_header:
        this.$route.meta && this.$route.meta.fixed_top_position_header
          ? this.$route.meta.fixed_top_position_header
          : null,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.getHeaderHeight();
      window.addEventListener("resize", this.getHeaderHeight);
    });
  },

  methods: {
    // handleNavigate() {
    //   this.visible = false;
    // },
    toggleMenu(is_expanded) {
      this.is_expanded = is_expanded;
    },
    getHeaderHeight() {
      let headerContentHeight = 0;
      const headerSlot = this.$refs.headerSlot;
      if (headerSlot && headerSlot.offsetHeight) {
        headerContentHeight = headerSlot.offsetHeight;
      }
      document.documentElement.style.setProperty(
        "--headerHeight",
        `${headerContentHeight + 48 + 39 + 27}px`
      );
    },
    toggleCollapse() {
      this.colToggle = !this.colToggle;
    },
    checkOverflow() {
      if (this.$route.meta.overflow_x_layout) {
        return "overflow-x-auto";
      } else {
        return "overflow-x-hidden";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-get-start {
  background: #fff;
  height: 4em;
  text-align: left;
  align-items: center;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.position-header1 {
  position: sticky;
  left: 0;
  z-index: 20;
}

.position-header2 {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 20;
}

/* .fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
} */

.fade-enter-active,
.fade-leave-active {
  transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: scale(0.3) translateY(-50%);
}

@keyframes fade-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
