<template>
  <div>
    <slot></slot>
  </div>
  <!-- <component :is="layout">
    <slot />
    
  </component> -->
</template>

<script>
const defaultLayout = "AppLayoutDefault";
const defaultMobile = "AppLayoutMobile";
export default {
  name: "AppLayout",
  computed: {
    layout() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        // mobile
        const layout = this.$route.meta.layout || defaultMobile;
        return () => import(`@/layouts/${layout}.vue`);
      } else {
        const layout = this.$route.meta.layout || defaultLayout;
        // console.log("layout", layout);
        return () => import(`@/layouts/${layout}.vue`);
      }
    },
  },
};
</script>
