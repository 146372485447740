<script>
import {
  H1_Web,
  H2_Web,
  H3_Web,
  H4_Web,
  Body_Web,
  BodyLink_Web,
  BodyBold_Web,
  BodySmall_Web,
  BodyBoldSmall_Web,
  H1_PDF,
  H2_PDF,
  Body_PDF,
  BodyBold_PDF,
} from "./text.styled.js";

export default {
  props: {
    type: String,
    color: String,
    classes: String,
    ellipsis: Boolean,
    disbreak: Boolean,
    href: String
  },
  components: {
    H1_Web,
    H2_Web,
    H3_Web,
    H4_Web,
    Body_Web,
    BodyLink_Web,
    BodyBold_Web,
    BodySmall_Web,
    BodyBoldSmall_Web,
    H1_PDF,
    H2_PDF,
    Body_PDF,
    BodyBold_PDF,
  },
  computed: {
    componentType() {
      switch (this.type) {
        case "h1_web":
          return "H1_Web";
        case "h2_web":
          return "H2_Web";
        case "h3_web":
          return "H3_Web";
        case "h4_web":
          return "H4_Web";
        case "body_web":
          return "Body_Web";
        case "link_web":
          return "BodyLink_Web";
        case "bold_web":
          return "BodyBold_Web";
        case "small_web":
          return "BodySmall_Web";
        case "bold_small_web":
          return "BodyBoldSmall_Web";
        case "h1_pdf":
          return "H1_PDF";
        case "h2_pdf":
          return "H2_PDF";
        case "body_pdf":
          return "Body_PDF";
        case "bold_pdf":
          return "BodyBold_PDF";
        default:
          return "Body_Web";
      }
    },
  },
};
</script>
<template>
  <component
    :is="componentType"
    :style="{
      color,
      'white-space': ellipsis ? 'nowrap' : 'normal',
      'word-break': disbreak ? 'normal' : 'break-word',
      overflow: ellipsis ? 'hidden' : 'visible',
      'text-overflow': ellipsis ? 'ellipsis' : '',
    }"
    :class="classes"
  >
    <slot />
  </component>
</template>

<!-- <template>
  <div>
    <H1_Web
      v-if="type === 'h1_web'"
      :style="`color: ${color}; white-space: 
      ${ellipsis ? 'nowrap' : 'normal'};`"
    >
      <slot />
    </H1_Web>
    <H2_Web
      v-if="type === 'h2_web'"
      :style="`color: ${color}; white-space: ${
        ellipsis ? 'nowrap' : 'normal'
      };`"
    >
      <slot />
    </H2_Web>
    <H3_Web
      v-if="type === 'h3_web'"
      :style="`color: ${color}; white-space: ${
        ellipsis ? 'nowrap' : 'normal'
      };`"
    >
      <slot />
    </H3_Web>
    <H4_Web
      v-if="type === 'h4_web'"
      :style="`color: ${color}; white-space: ${
        ellipsis ? 'nowrap' : 'normal'
      };`"
    >
      <slot />
    </H4_Web>
    <Body_Web
      v-if="type === 'body_web'"
      :style="`color: ${color}; white-space: ${
        ellipsis ? 'nowrap' : 'normal'
      };`"
    >
      <slot />
    </Body_Web>
    <BodyLink_Web
      v-if="type === 'link_web'"
      :style="`color: ${color}; white-space: ${
        ellipsis ? 'nowrap' : 'normal'
      };`"
    >
      <slot />
    </BodyLink_Web>
    <BodyBold_Web
      v-if="type === 'bold_web'"
      :style="`color: ${color}; white-space: ${
        ellipsis ? 'nowrap' : 'normal'
      };`"
    >
      <slot />
    </BodyBold_Web>
    <BodySmall_Web
      v-if="type === 'small_web'"
      :style="`color: ${color}; white-space: ${
        ellipsis ? 'nowrap' : 'normal'
      };`"
    >
      <slot />
    </BodySmall_Web>
    <BodyBoldSmall_Web
      v-if="type === 'bold_small_web'"
      :style="`color: ${color}; white-space: ${
        ellipsis ? 'nowrap' : 'normal'
      };`"
    >
      <slot />
    </BodyBoldSmall_Web>
    <H1_PDF
      v-if="type === 'h1_pdf'"
      :style="`color: ${color}; white-space: ${
        ellipsis ? 'nowrap' : 'normal'
      };`"
    >
      <slot />
    </H1_PDF>
    <H2_PDF
      v-if="type === 'h2_pdf'"
      :style="`color: ${color}; white-space: ${
        ellipsis ? 'nowrap' : 'normal'
      };`"
    >
      <slot />
    </H2_PDF>
    <Body_PDF
      v-if="type === 'body_pdf'"
      :style="`color: ${color}; white-space: ${
        ellipsis ? 'nowrap' : 'normal'
      };`"
    >
      <slot />
    </Body_PDF>
    <BodyBold_PDF
      v-if="type === 'bold_pdf'"
      :style="`color: ${color}; white-space: ${
        ellipsis ? 'nowrap' : 'normal'
      };`"
    >
      <slot />
    </BodyBold_PDF>
  </div>
</template> -->