export default {
    namespaced: true,
    state: {
        lang: null,
    },
    mutations: {
        setLanguage: (state, payload) => {
            state.lang = payload
        },
    },
    actions: {
        setLanguage : (state, payload) => {
            state.commit("setLanguage", payload);
        },
    },
    getters: {
        selectLangData: (state) => state.lang,
    },
}