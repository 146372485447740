<template>
  <div style="z-index: 99">
    <styled-button
      v-if="showNavigate"
      type="tutorial"
      @on-click="$bvModal.show('modal-steps-for-use')"
    >
      <tutorial-icon />
    </styled-button>
    <aside id="aside" :class="`${is_expanded ? 'is-expanded' : ''}`">
      <div class="menu">
        <div
          :class="` ${is_expanded ? 'container-sidebar' : 'container-sidebar-collapse'}`"
        >
          <a-tooltip placement="right">
            <template #title>
              <typo color="#fff">{{ organization ? organization.name_en : "" }}</typo> 
            </template>
            <div
              class="button d-flex mt-2 px-2 align-items-center profile header-hover"
              :class="{
                'cursor-pointer': $store.state.admin_type == 1,
                'profile-card': is_expanded,
              }"
            >
              <div>
                <b-avatar
                  variant="info"
                  rounded
                  :src="organization.file_url"
                  v-if="organization"
                />
                <div v-else>
                  <camera-icon />
                </div>
              </div>
              <div class="select-box" v-if="is_expanded">
                <div
                  tabindex="1"
                  :class="`${is_dropdown ? 'is_dropdown' : ''}`"
                  v-click-outside="showDropdown"
                  @click="isDropdown()"
                >
                  <typo
                    type="body_web"
                    style="width: 155px; padding-left: 0.5rem"
                    class="text-left"
                    ellipsis
                    v-if="organization"
                  >
                    {{ organization.name_en }}
                  </typo>
                  <b-skeleton
                    v-else
                    style="
                      margin-left: 7px;
                      margin-right: 10px;
                      height: 1rem;
                      width: 155px;
                    "
                  />
                </div>
              </div>
            </div>
          </a-tooltip>
        </div>

        <!-- menu -->

        <div
          :class="` ${is_expanded ? 'container-sidebar' : 'container-sidebar-collapse'}`"
        >
          <router-link
            v-if="
              permissions && permissions.overview && permissions.overview.access == true
            "
            to="/overview-dashboard"
            class="button d-flex px-2 mt-2 underline-none"
            :class="[
              $route.fullPath === '/overview-dashboard' ||
              $route.fullPath === '/insight-dashboard'
                ? 'active-child'
                : '',
            ]"
            active-class="active"
            aria-expanded="false"
          >
            <div class="d-flex w-100">
              <span class="tooltip-info-menu">
                <div
                  style="margin-right: 10px"
                  class="d-flex align-items-center"
                  :style="`padding-left: ${
                    ($route.fullPath === '/overview-dashboard' ||
                      $route.fullPath === '/insight-dashboard') &&
                    !is_expanded
                      ? '6px'
                      : '10px'
                  };`"
                >
                  <analytics-icon />
                  <div
                    class="active-selected-box"
                    v-if="
                      ($route.fullPath === '/overview-dashboard' ||
                        $route.fullPath === '/insight-dashboard') &&
                      !is_expanded
                    "
                  />
                </div>
                <span v-if="!is_expanded" class="tooltip-text-info-menu">
                  <div class="tooltip-list">{{ $t("BTN_DASHBOARD") }}</div>
                </span>
              </span>
              <typo ellipsis type="body_web" class="text text-left" style="width: 155px">
                {{ $t("BTN_DASHBOARD") }}
              </typo>
              <div
                class="active-selected-box"
                v-if="
                  $route.fullPath === '/overview-dashboard' ||
                  $route.fullPath === '/insight-dashboard'
                "
              />
            </div>
          </router-link>

          <div
            v-if="
              permissions && permissions.setting && permissions.setting.access == true
            "
            @click="expandSetting"
          >
            <router-link
              to="#"
              class="button sub-menu d-flex px-2 mt-2 underline-none dropdown"
              :class="[
                `${is_expanded ? 'is-expanded' : ''}`,
                `${
                  isBgActiveSubmenuSetting() ? 'bg-active-icon-submenu' : 'bg-transparent'
                }`,
              ]"
              active-class="active"
              data-bs-toggle="collapse"
              :data-bs-target="'#setting'"
              :aria-expanded="isOptionSetting ? true : false"
            >
              <span class="tooltip-info-menu">
                <div
                  style="margin-right: 10px"
                  class="d-flex align-items-center"
                  :style="`padding-left: ${
                    ($route.fullPath === '/organization-setting/organization' ||
                      $route.fullPath === '/organization-setting/building' ||
                      $route.fullPath === '/organization-setting/branch' ||
                      $route.fullPath === '/organization-setting/department' ||
                      $route.fullPath === '/organization-setting/setting-fiscalyear' ||
                      $route.fullPath === '/manage-activities' ||
                      $route.fullPath === '/manage-user-accounts/manage-users' ||
                      $route.fullPath === '/manage-user-accounts/assign-task' ||
                      $route.fullPath === '/manage-user-accounts/manage-auditors' ||
                      $route.fullPath ===
                        '/manage-user-accounts/manage-role-permission') &&
                    !is_expanded
                      ? '6px'
                      : '10px'
                  };`"
                >
                  <setting-icon />
                  <div
                    class="active-selected-box"
                    v-if="
                      ($route.fullPath === '/organization-setting/organization' ||
                        $route.fullPath === '/organization-setting/building' ||
                        $route.fullPath === '/organization-setting/branch' ||
                        $route.fullPath === '/organization-setting/department' ||
                        $route.fullPath === '/organization-setting/setting-fiscalyear' ||
                        $route.fullPath === '/manage-activities' ||
                        $route.fullPath === '/manage-user-accounts/manage-users' ||
                        $route.fullPath === '/manage-user-accounts/assign-task' ||
                        $route.fullPath === '/manage-user-accounts/manage-auditors' ||
                        $route.fullPath ===
                          '/manage-user-accounts/manage-role-permission') &&
                      !is_expanded
                    "
                  />
                </div>

                <!-- Tooltip menu -->
                <div v-if="!is_expanded" class="dropdown-content">
                  <div style="border-radius: 8px; background: var(--primary-white)">
                    <a
                      class="d-flex"
                      :class="[
                        isActiveTooltipMenuOrganization()
                          ? 'bg-active-icon-submenu-tooltip'
                          : 'bg-inactive-icon-submenu-tooltip',
                      ]"
                      @click="toCompanyPage()"
                      v-if="
                        permissions &&
                        permissions.organization_structure &&
                        permissions.organization_structure.access == true
                      "
                    >
                      <div style="margin-right: 10px">
                        <status-circle-icon />
                      </div>
                      {{ $t("BTN_SETTING_ORGANIZATION") }}
                    </a>
                    <a
                      class="d-flex"
                      :class="[
                        !is_expanded && $route.fullPath.includes('/manage-activities')
                          ? 'bg-active-icon-submenu-tooltip'
                          : 'bg-inactive-icon-submenu-tooltip',
                      ]"
                      @click="toActivityList()"
                      v-if="
                        permissions &&
                        permissions.measure_list &&
                        permissions.measure_list.access == true
                      "
                    >
                      <div style="margin-right: 10px">
                        <status-circle-icon />
                      </div>
                      {{ $t("TXT_ACTIVITY_INVENTORY") }}
                    </a>
                    <a
                      class="d-flex"
                      :class="[
                        !is_expanded && $route.fullPath.includes('manage-users')
                          ? 'bg-active-icon-submenu-tooltip'
                          : 'bg-inactive-icon-submenu-tooltip',
                      ]"
                      @click="toUserMember()"
                      v-if="
                        permissions &&
                        permissions.user_account &&
                        permissions.user_account.access == true
                      "
                    >
                      <div style="margin-right: 10px">
                        <status-circle-icon />
                      </div>
                      {{ $t("BTN_MANAGE_USER_ACCOUNTS") }}
                    </a>
                  </div>
                </div>
              </span>

              <div class="d-flex w-100 align-items-baseline">
                <typo ellipsis type="body_web" class="text" style="width: 155px">
                  {{ $t("BTN_SETTING_MAIN") }}
                </typo>
              </div>

              <span
                v-if="is_expanded"
                class="ms-auto"
                :class="`${isOptionSetting ? 'isOptionSetting' : 'noOptionSetting'}`"
              >
                <i class="fa-solid fa-chevron-right" style="color: var(--primary-black)">
                </i>
              </span>
            </router-link>
          </div>

          <!-- collapse setting menu -->
          <div
            v-if="is_expanded"
            id="setting"
            :class="[isOptionSetting ? 'collapse show' : 'collapsing']"
          >
            <div class="list-unstyled">
              <router-link
                v-if="
                  permissions &&
                  permissions.organization_structure &&
                  permissions.organization_structure.access == true
                "
                to="/organization-setting/organization"
                class="button list-menu d-flex px-3 mt-2 underline-none"
                :class="[
                  $route.fullPath === '/organization-setting/organization' ||
                  $route.fullPath === '/organization-setting/building' ||
                  $route.fullPath === '/organization-setting/branch' ||
                  $route.fullPath === '/organization-setting/department' ||
                  $route.fullPath === '/organization-setting/setting-fiscalyear'
                    ? 'active-child'
                    : '',
                ]"
                active-class="active"
              >
                <div class="d-flex w-100" style="padding-left: 1rem">
                  <div style="margin-left: 6px; margin-right: 10px">
                    <status-circle-icon />
                  </div>
                  <typo
                    ellipsis
                    type="body_web"
                    class="text text-left"
                    style="width: 155px"
                  >
                    {{ $t("BTN_SETTING_ORGANIZATION") }}
                  </typo>
                  <div
                    class="active-selected-box"
                    v-if="
                      $route.fullPath === '/organization-setting/organization' ||
                      $route.fullPath === '/organization-setting/building' ||
                      $route.fullPath === '/organization-setting/branch' ||
                      $route.fullPath === '/organization-setting/department' ||
                      $route.fullPath === '/organization-setting/setting-fiscalyear'
                    "
                  />
                </div>
              </router-link>
              <router-link
                v-if="
                  permissions &&
                  permissions.measure_list &&
                  permissions.measure_list.access == true
                "
                to="/manage-activities"
                class="button list-menu d-flex px-3 mt-2 underline-none"
                active-class="active"
              >
                <div class="d-flex w-100" style="padding-left: 1rem">
                  <div style="margin-left: 6px; margin-right: 10px">
                    <status-circle-icon />
                  </div>
                  <typo
                    ellipsis
                    type="body_web"
                    class="text text-left"
                    style="width: 155px"
                  >
                    {{ $t("TXT_ACTIVITY_INVENTORY") }}
                  </typo>
                  <div
                    class="active-selected-box"
                    v-if="$route.fullPath.includes('/manage-activities')"
                  />
                </div>
              </router-link>
              <router-link
                v-if="
                  permissions &&
                  permissions.user_account &&
                  permissions.user_account.access == true
                "
                to="/manage-user-accounts/manage-users"
                class="button list-menu d-flex px-3 mt-2 underline-none"
                active-class="active"
                :class="[
                  $route.fullPath === '/manage-user-accounts/manage-users' ||
                  $route.fullPath === '/manage-user-accounts/manage-role-permission' ||
                  $route.fullPath === '/manage-user-accounts/assign-task' ||
                  $route.fullPath === '/manage-user-accounts/manage-auditors'
                    ? 'active-child'
                    : '',
                ]"
              >
                <div class="d-flex w-100" style="padding-left: 1rem">
                  <div style="margin-left: 6px; margin-right: 10px">
                    <status-circle-icon />
                  </div>
                  <typo
                    ellipsis
                    type="body_web"
                    class="text text-left"
                    style="width: 155px"
                  >
                    {{ $t("BTN_MANAGE_USER_ACCOUNTS") }}
                  </typo>
                  <div
                    class="active-selected-box"
                    v-if="
                      $route.fullPath === '/manage-user-accounts/manage-users' ||
                      $route.fullPath ===
                        '/manage-user-accounts/manage-role-permission' ||
                      $route.fullPath === '/manage-user-accounts/assign-task' ||
                      $route.fullPath === '/manage-user-accounts/manage-auditors'
                    "
                  />
                </div>
              </router-link>
            </div>
          </div>

          <router-link
            v-if="
              permissions &&
              permissions.filling_out &&
              permissions.filling_out.access == true
            "
            to="/measurement-list"
            class="button d-flex px-2 mt-2 underline-none"
            active-class="active"
            aria-expanded="false"
            style="display: flex; place-items: center"
            :class="[$route.fullPath.includes('/measurement-list') ? 'active-child' : '']"
          >
            <div class="d-flex w-100">
              <span class="tooltip-info-menu">
                <div
                  style="margin-right: 10px"
                  class="d-flex align-items-center"
                  :style="`padding-left: ${
                    $route.fullPath.includes('/measurement-list') && !is_expanded
                      ? '6px'
                      : '10px'
                  };`"
                >
                  <memo-note-icon />
                  <div
                    class="active-selected-box"
                    v-if="$route.fullPath.includes('/measurement-list') && !is_expanded"
                  />
                </div>
                <span v-if="!is_expanded" class="tooltip-text-info-menu">
                  <div class="tooltip-list">
                    {{ $t("BC_FILLIN_MEASURE") }}
                  </div>
                </span>
              </span>
              <typo ellipsis type="body_web" class="text text-left" style="width: 155px">
                {{ $t("BC_FILLIN_MEASURE") }}
              </typo>
              <div
                class="active-selected-box"
                v-if="$route.fullPath.includes('/measurement-list')"
              />
            </div>
          </router-link>

          <router-link
            v-if="
              permissions &&
              permissions.application &&
              permissions.application.access == true
            "
            to="/approval"
            class="button d-flex px-2 mt-2 underline-none"
            active-class="active"
            aria-expanded="false"
            style="display: flex; place-items: center"
          >
            <div class="d-flex w-100">
              <span class="tooltip-info-menu">
                <div
                  style="margin-right: 10px"
                  class="d-flex align-items-center"
                  :style="`padding-left: ${
                    $route.fullPath === '/approval' && !is_expanded ? '6px' : '10px'
                  };`"
                >
                  <mark-chat-icon />
                  <div
                    class="active-selected-box"
                    v-if="$route.fullPath === '/approval' && !is_expanded"
                  />
                </div>
                <span v-if="!is_expanded" class="tooltip-text-info-menu">
                  <div class="tooltip-list">
                    {{ $t("BTN_APPROVE_LISTS") }}
                  </div>
                </span>
              </span>
              <typo ellipsis type="body_web" class="text text-left" style="width: 155px">
                {{ $t("BTN_APPROVE_LISTS") }}
              </typo>
              <div class="active-selected-box" v-if="$route.fullPath === '/approval'" />
            </div>
          </router-link>

          <div
            v-if="
              permissions && permissions.custom_ef && permissions.custom_ef.access == true
            "
            @click="expandEF"
          >
            <router-link
              to="#"
              class="button sub-menu d-flex px-2 mt-2 underline-none dropdown"
              :class="[
                `${is_expanded ? 'is-expanded' : ''}`,
                `${isActiveSubmenuEF() ? 'bg-active-icon-submenu' : 'bg-transparent'}`,
              ]"
              active-class="active"
              data-bs-toggle="collapse"
              :data-bs-target="'#ef'"
              :aria-expanded="isOptionEF ? true : false"
            >
              <span class="tooltip-info-menu">
                <div
                  style="margin-right: 10px"
                  class="d-flex align-items-center"
                  :style="`padding-left: ${
                    ($route.fullPath === '/mapping-emission-factor' ||
                      $route.fullPath === '/custom-emission-factor') &&
                    !is_expanded
                      ? '6px'
                      : '10px'
                  };`"
                >
                  <cloud-icon />
                  <div
                    class="active-selected-box"
                    v-if="
                      ($route.fullPath === '/mapping-emission-factor' ||
                        $route.fullPath === '/custom-emission-factor') &&
                      !is_expanded
                    "
                  />
                </div>
                <div v-if="!is_expanded" class="dropdown-content">
                  <a
                    class="d-flex"
                    :class="[
                      !is_expanded && $route.fullPath == '/mapping-emission-factor'
                        ? 'bg-active-icon-submenu-tooltip'
                        : 'bg-inactive-icon-submenu-tooltip',
                    ]"
                    @click="toMappingEf()"
                    v-if="
                      permissions &&
                      permissions.custom_ef &&
                      permissions.custom_ef.access == true
                    "
                  >
                    <div style="margin-right: 10px">
                      <status-circle-icon />
                    </div>
                    Mapping EF
                  </a>

                  <a
                    class="d-flex"
                    :class="[
                      !is_expanded && $route.fullPath == '/custom-emission-factor'
                        ? 'bg-active-icon-submenu-tooltip'
                        : 'bg-inactive-icon-submenu-tooltip',
                    ]"
                    @click="toCustomEf()"
                    v-if="
                      permissions &&
                      permissions.custom_ef &&
                      permissions.custom_ef.access == true
                    "
                  >
                    <div style="margin-right: 10px">
                      <status-circle-icon />
                    </div>
                    {{ $t("LB_CUSTOM_EF") }}
                  </a>
                </div>
              </span>

              <div class="d-flex w-100 align-items-baseline">
                <typo ellipsis class="text" type="body_web" style="width: 155px">
                  Emission Factor (EF)
                </typo>
              </div>

              <span
                v-if="is_expanded"
                class="ms-auto"
                :class="`${isOptionEF ? 'isOptionSetting' : 'noOptionSetting'}`"
              >
                <i class="fa-solid fa-chevron-right" style="color: var(--primary-black)">
                </i>
              </span>
            </router-link>
          </div>

          <!-- collapse ef menu -->
          <div
            v-if="is_expanded"
            id="ef"
            :class="[isOptionEF ? 'collapse show' : 'collapsing']"
          >
            <div class="list-unstyled">
              <router-link
                v-if="
                  permissions &&
                  permissions.custom_ef &&
                  permissions.custom_ef.access == true
                "
                to="/mapping-emission-factor"
                class="button list-menu d-flex px-3 mt-2 underline-none"
                :class="[
                  $route.fullPath === '/mapping-emission-factor' ? 'active-child' : '',
                ]"
                active-class="active"
              >
                <div class="d-flex w-100" style="padding-left: 1rem">
                  <div style="margin-left: 6px; margin-right: 10px">
                    <status-circle-icon />
                  </div>
                  <typo
                    ellipsis
                    type="body_web"
                    class="text text-left"
                    style="width: 155px"
                  >
                    Mapping EF
                  </typo>
                  <div
                    class="active-selected-box"
                    v-if="$route.fullPath === '/mapping-emission-factor'"
                  />
                </div>
              </router-link>
              <router-link
                v-if="
                  permissions &&
                  permissions.custom_ef &&
                  permissions.custom_ef.access == true
                "
                to="/custom-emission-factor"
                class="button list-menu d-flex px-3 mt-2 underline-none"
                active-class="active"
              >
                <div class="d-flex w-100" style="padding-left: 1rem">
                  <div style="margin-left: 6px; margin-right: 10px">
                    <status-circle-icon />
                  </div>
                  <typo
                    ellipsis
                    type="body_web"
                    class="text text-left"
                    style="width: 155px"
                  >
                    {{ $t("LB_CUSTOM_EF") }}
                  </typo>
                  <div
                    class="active-selected-box"
                    v-if="$route.fullPath === '/custom-emission-factor'"
                  />
                </div>
              </router-link>
            </div>
          </div>

          <router-link
            v-if="permissions && permissions.audit && permissions.audit.access == true"
            to="/verify-information"
            class="button sub-menu d-flex px-2 mt-2 underline-none"
            active-class="active"
            aria-expanded="false"
            style="display: flex; place-items: center"
            :class="[
              $route.fullPath.includes('/verify-information') ? 'active-child' : '',
            ]"
          >
            <div class="d-flex w-100">
              <span class="tooltip-info-menu">
                <div
                  style="margin-right: 10px"
                  class="d-flex align-items-center"
                  :style="`padding-left: ${
                    $route.fullPath.includes('/verify-information') && !is_expanded
                      ? '6px'
                      : '10px'
                  };`"
                >
                  <report-icon />
                  <div
                    class="active-selected-box"
                    v-if="$route.fullPath.includes('/verify-information') && !is_expanded"
                  />
                </div>
                <span v-if="!is_expanded" class="tooltip-text-info-menu">
                  <div class="tooltip-list">
                    {{ $t("LB_VERIFY_INFORMATION") }}
                  </div>
                </span>
              </span>
              <typo ellipsis type="body_web" class="text text-left" style="width: 155px">
                {{ $t("LB_VERIFY_INFORMATION") }}
              </typo>
              <div
                class="active-selected-box"
                v-if="$route.fullPath.includes('/verify-information')"
              />
            </div>
          </router-link>
        </div>
      </div>
      <div
        @click="ToggleMenu"
        style="height: inherit; margin-left: -15px; margin-right: -13px"
      />
      <div class="footer-area">
        <hr
          style="
            border: 1px solid var(--primary-black);
            margin-left: 16px;
            margin-right: 16px;
          "
          :hidden="!is_expanded"
        />
        <hr
          style="
            border: 1px solid var(--primary-black);
            margin-left: 10px;
            margin-right: 10px;
          "
          :hidden="is_expanded"
        />
        <div
          class="footer-menu"
          :class="`${is_expanded ? 'container-sidebar' : 'container-sidebar-collapse'}`"
        >
          <div class="footer-icon">
            <div class="collapse show" id="profile">
              <div class="list-unstyled">
                <div
                  class="button list-menu d-flex mt-2 underline-none cursor-pointer"
                  active-class="active"
                  :style="`width: ${!is_expanded ? '44px' : ''};`"
                  @click="openSettingProfile()"
                >
                  <div class="d-flex w-100">
                    <div
                      style="
                        padding-left: 2px;
                        margin-right: 10px;
                        padding-top: 3px;
                        padding-bottom: 3px;
                      "
                      class="d-flex align-items-center"
                    >
                      <avatar-icon v-if="!main_company || !main_company.user.file" />
                      <b-avatar
                        v-else
                        :src="main_company.user.file.file_url"
                        style="width: 32px; height: 32px; border-radius: 100%"
                      />
                    </div>
                    <div
                      v-if="is_expanded"
                      style="width: 150px"
                      class="align-content-center my-auto"
                    >
                      <typo ellipsis type="bold_web" v-if="profile && profile.first_name">
                        {{ profile.first_name }} {{ profile.last_name }}
                      </typo>

                      <typo type="body_web" v-if="profile && !profile.first_name">
                        {{ profile ? profile.email : "" }}
                      </typo>
                      <b-skeleton v-if="!profile" style="height: 1rem" />
                      <typo type="body_web">
                        {{ profile ? profile.job_role : "" }}
                      </typo>
                    </div>
                  </div>
                </div>
                <div
                  v-if="$store.state.oauth_token == null"
                  class="button list-menu d-flex mt-2 underline-none cursor-pointer"
                  active-class="active"
                  :style="`width: ${!is_expanded ? '44px' : ''};`"
                  @click="openModalChangePassword()"
                >
                  <div class="d-flex w-100">
                    <div
                      style="padding-left: 10px; margin-right: 10px"
                      class="d-flex align-items-center"
                    >
                      <lock-open-icon />
                    </div>
                    <typo
                      ellipsis
                      type="body_web"
                      class="text text-left"
                      style="width: 155px"
                    >
                      {{ $t("TXT_CHANGE_PASSWORD") }}
                    </typo>
                  </div>
                </div>
                <div
                  class="button list-menu d-flex mt-2 underline-none cursor-pointer"
                  active-class="active"
                  :style="`width: ${!is_expanded ? '44px' : ''};`"
                  @click="openConfirmLogout()"
                >
                  <div class="d-flex w-100">
                    <div
                      style="padding-left: 10px; margin-right: 10px"
                      class="d-flex align-items-center"
                    >
                      <exit-icon />
                    </div>
                    <typo
                      ellipsis
                      type="body_web"
                      class="text text-left"
                      style="width: 155px"
                    >
                      {{ $t("BTN_LOG_OUT") }}
                    </typo>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
    <ModalFormProfile
      v-if="count_double_login != 1"
      :main-company="user_data"
      @get-member="getMainCompany"
      @get-user="getUser"
      @reset-profile="resetProfile"
    />
    <ModalChangePassword
      v-if="count_double_login != 1"
      :user-type="profile ? profile.type : 1"
    />
    <ModalFormPassword
      v-if="count_double_login != 1"
      :user-type="profile ? profile.type : 1"
      :force-password="profile && profile.is_force_password_reset"
    />
    <ModalStepsForUse v-if="count_double_login != 1" />

    <ModalWarning
      :id="modal_warning_id"
      :title="modal_warning_title"
      :content="modal_warning_content"
      :btnSubmitName="modal_warning_btn_name"
      :submitFunction="submitFunction"
    />
  </div>
</template>

<script>
import { httpClient } from "@/services/axiosInstance.js";
import { apiURL } from "@/configs/serverConfig";
import axios from "axios";
import { mapGetters } from "vuex";
import moment from "moment";

import ModalStepsForUse from "@/components/Modal/ModalStepsForUse.vue";
import ModalChangePassword from "@/components/Modal/settingprofile/ModalChangePassword.vue";
import ModalFormProfile from "@/components/Sidebar/modals/ModalFormProfile.vue";
import ModalFormPassword from "@/components/Sidebar/modals/ModalFormPassword.vue";
import ModalWarning from "@/components/Modal/Alert/ModalWarning.vue";
// import { jwtDecode } from "jwt-decode";

export default {
  props: ["toggleMenu"],
  components: {
    ModalChangePassword,
    ModalStepsForUse,
    ModalFormProfile,
    ModalFormPassword,
    ModalWarning,
  },
  watch: {
    toggleMenu: "setExpanded",
  },
  data() {
    return {
      optionsCompanies: [],
      is_expanded: this.$store.state.menu.is_expanded,
      isOptionSetting: this.$store.state.menu.is_option_setting,
      isOptionEF: this.$store.state.menu.is_option_ef,
      isOptionProfile: false,
      profile: null,
      main_company: null,

      formUpload: null,
      responseDataUpload: null,
      isApprove: false,
      roleAction: this.$store.state.role_action,
      branch: null,
      // company_list: [],
      // setupType: this.$store.state.setup_type, //ตั้งค่าอย่างง่าย
      // tabDashboard: null,
      isLoadingMenu: false,
      is_dropdown: false,
      primaryMainCompany: null,
      user_data: null,
      countDropdownCompany: 0,
      companies: null,
      organization: null,
      showNavigate: true,
      state_oauth: null,
      modal_warning_id: null,
      modal_warning_title: null,
      modal_warning_content: null,
      modal_warning_btn_name: null,
      count_double_login:
        this.$store && this.$store.state && this.$store.state.count_double_login,
    };
  },
  computed: {
    ...mapGetters({
      permissions: "getPermissions",
      setupType: "getSetupType",
      auditorType: "getAuditorType",
      loginType: "getLoginType",
    }),
  },
  async mounted() {
    if (this.$store.state.count_double_login == 1) {
      this.modalWarningDoubleLogin();
      // this.modal_warning_id = await "modal-warning-double-login";
      // this.modal_warning_title = 'แจ้งเตือนการเข้าใช้ระบบทับซ้อน';
      // this.modal_warning_content = 'ระบบจะทำการออกจากระบบโดยอัตโนมัติ หากต้องการใช้งานต่อ กรุณาเข้าสู่ระบบใหม่อีกครั้ง';
      // this.modal_warning_btn_name = this.$t('BTN_CLOSE');
      // console.log('modal_warning_id',this.modal_warning_id)
      // this.$bvModal.show(this.modal_warning_id);
    } else {
      await this.getUser();
      await this.getMainCompany();
      // await this.getCompanyList();
      await this.getDataCompanies();
      // this.getDashboardTab();
      if (this.profile && this.profile.is_force_password_reset) {
        this.openModalChangePassword();
      }
    }

    // this.$bvModal.show("modal-warning-double-login");

    window.addEventListener("resize", () => {
      if (window.innerWidth <= 1024) {
        this.is_expanded = false;
        localStorage.setItem("is_expanded", this.is_expanded);
        if (!this.is_expanded) {
          this.isOptionSetting = false;
          localStorage.setItem("is_option_setting", this.isOptionSetting);
        }
      }
    });
  },
  methods: {
    generateState() {
      this.state_oauth = Math.random().toString(36).substring(7);
    },
    setExpanded(is_expanded) {
      this.is_expanded = is_expanded;
    },
    async getUser() {
      if (this.$store.state.token !== "") {
        httpClient
          .get(`${apiURL}/user`, {
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
              Authorization: `Bearer ${this.$store.state.token}`,
            },
          })
          .then((response) => {
            this.branch = response.data.primary_main_company;
            this.profile = response.data;
          });
      } else {
        if (this.$router.currentRoute.path.includes("mobile")) {
          this.$router.push({ name: "Signin CPF" });
        } else {
          this.$router.push("/");
        }
      }
    },
    showDropdown() {
      this.is_dropdown = false;
    },

    async getDataCompanies() {
      const url = `${apiURL}/company?size=20&page=1&sort=id ASC`;

      try {
        const companies = await httpClient.get(url);
        if (companies.data.entities && companies.data.entities.length > 0) {
          companies.data.entities.forEach((list) => {
            if (list.is_default) {
              this.organization = {
                ...list,
                file_url: list.file ? list.file.file_url : null,
              };
            }
          });
          this.companies = companies.data.entities;
        } else {
          this.companies = [];
        }
      } catch (err) {
        this.$toast.error({
          component: "styled-toast",
          props: {
            type: "error",
            message: err.response.data.message,
          },
        });
      }
    },
    // async getCompanyList() {
    //   this.company_list = [];
    //   const url = `${apiURL}/member/main_companies?login_from=${this.loginType}`;
    //   try {
    //     const company_list = await httpClient.get(url);
    //     this.countDropdownCompany = company_list.data.length;
    //     if (
    //       company_list.data.find((data) => data.id == this.primaryMainCompany)
    //     ) {
    //       // เจอบริษัทที่เคยเข้าใช้งาน
    //       this.company_list = company_list.data;
    //     } else {
    //       this.company_list.push({ id: 0, name_en: "กรุณาเลือกบริษัท" });
    //       for (let company of company_list.data) {
    //         this.company_list.push(company);
    //       }
    //       this.branch = 0;
    //     }
    //     this.company_list.forEach((element) => {
    //       this.optionsCompanies.push({
    //         text: element.name_en,
    //         value: element.id,
    //       });
    //     });
    //     // console.log("company_list", this.company_list);
    //   } catch (err) {
    //   }
    // },
    closedoc() {
      document.getElementById("docs_cwallet").style.display = "none";
    },
    expandSetting() {
      this.isOptionSetting = !this.isOptionSetting;
      this.$store.dispatch("menu/setIsOptionSetting", this.isOptionSetting);
    },
    expandEF() {
      this.isOptionEF = !this.isOptionEF;
      this.$store.dispatch("menu/setIsOptionEf", this.isOptionEF);
    },
    expandProfile() {
      this.isOptionProfile = !this.isOptionProfile;
    },
    openConfirmLogout() {
      this.modal_warning_id = "modal-logout";
      this.modal_warning_title = this.$t("TXT_LOG_OUT");
      this.modal_warning_content = this.$t("TXT_CONFIRM_LOG_OUT");
      this.modal_warning_btn_name = this.$t("BTN_LOG_OUT");
      this.$nextTick(() => {
        this.$bvModal.show("modal-logout");
      });
    },

    async logout() {
      this.$store.commit("setToken", "");
      this.$store.commit("setRoleAction", null);
      if (this.profile.type == 2 || this.profile.type == 3) {
        //auditor
        this.$router.push("/login-auditor");
      } else {
        this.$router.push("/");
      }
      if (this.$store.state.oauth_token) {
        this.$store.commit("setoAuthToken", null);
        this.$store.commit("setoAuthRefreshToken", null);
        let logoutSession = window.open(
          `${process.env.VUE_APP_SETLINK_URL}/authen/logout`,
          "_blank"
        );
        const myTimeout = setTimeout(() => {
          logoutSession.close();
          clearTimeout(myTimeout);
        }, 300);
      }

      // this.logoutImprecit();

      // let token = 'eyJraWQiOiI2YThmYjJlMS01Zjc1LTRmM2EtYTQyYS1mMzAwZmM2NWEyOWEiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJtYW51dHRoYS5tIiwiaXNzIjoiaHR0cHM6Ly9zc291YXQuc2V0cG9ydGFsLnNldC5vci50aC9hdXRoZW4iLCJOX0xPR0lOIjoibWFudXR0aGEubSIsImF1ZCI6IlNFVExpbmsiLCJDT0RFIjoiMCIsIm5iZiI6MTcxOTg5ODc2MiwiSV9VU0VSIjoxNTgyMjcwNywiUk9MRVMiOlsiTFNUX0NSRUFUT1IiXSwic2NvcGUiOlsib3BlbmlkIl0sIlRJTUVTVEFNUCI6MTcxOTg5ODc2MiwiZXhwIjoxNzE5OTEzMTYyLCJpYXQiOjE3MTk4OTg3NjJ9.lnQNnGsAboJ0xx4EzChr416gk2GH0b2GbYrB0VRI4WZBtWgSn24eHWGEV4VV1Cn9z3-iTk67uYItLmZRY2PTJu-yVZPcuUuIRd4-Syl8F1LgClFbsXid4IE-1VWCAO4qAg3oQHbZv7fP-1oPqDU7ZfJyF-gvnRVYCZcSHN6sBch6GuMhzaKM3PcWjggkH79suEvSQ2CJZQgCFLrRKEDJySRSh91cnr35JBzhXKn0WtMrlamuNk4a2-6DZZJdIZJMbD6Ar4wSPCdX5ZqDQLFVyRPyc6A-xDIHRYmJ6uaHEEDhv-VQgLP94B2LIF6-DJXypr-aqhOY1W3ljXCM1NwMXtDLVklJy_q1n6MNKhjyzz_ANcCLsOuWwvOhqg2vm3CgDdLTEfHhOwZSMntTI4YpU0sSntiWcjXsawEtA1Ord7lSv9KttjYmcxLUqKB3zM-yHedGF7mI-bhkJ5yS2xKTpI2n9xj1SLXJwFxI3BO86_v8WqGYd_7I2_xGA7amnCHQrtP6sQgcD_onJHMr2_BPV3daHSasJGl2G-xvoPfiNDryydirFIkZKBNhuG6tXdYGdUMjDCLxzvyY2rPF43VpTP0iuw1637xxT2hS6rTAswl6MtUjBjJ8FsCqKL3JF1HMyd56KPsD7ANT2tunj7W562xFWdPDsyAdx5WQyVaOqp0'
      // let check_token_expire = jwtDecode(token).exp * 1000 - date.getTime();

      // if (this.$store.state.oauth_token) {
      //   const date = new Date();
      //   let check_token_expire =
      //     jwtDecode(this.$store.state.oauth_token).exp * 1000 - date.getTime();
      //   if (check_token_expire <= 0) {
      //     await this.refreshTokenOauth();
      //     await this.logoutImprecit();
      //   } else {
      //     await this.logoutImprecit();
      //   }
      // }
    },
    ToggleMenu() {
      this.is_expanded = !this.is_expanded;
      this.$store.dispatch("menu/setIsExpanded", this.is_expanded);

      this.$store.dispatch(
        "menu/setStatusCloseMenu",
        !this.$store.state.menu.is_close_menu
      );

      if (!this.is_expanded) {
        this.isOptionSetting = false;
        this.isOptionEF = false;
      } else {
        this.isOptionSetting = this.$store.state.menu.is_option_setting;
        this.isOptionEF = this.$store.state.menu.is_option_ef;
        this.$store.dispatch("menu/setIsOptionSetting", this.isOptionSetting);
        this.$store.dispatch("menu/setIsOptionEf", this.isOptionEF);
      }
    },
    OptionSetting() {
      this.is_expanded = true;
      localStorage.setItem("is_expanded", true);
      if (this.is_expanded) {
        this.isOptionSetting = !this.isOptionSetting;
        this.$store.dispatch("menu/setIsOptionSetting", this.isOptionSetting);
        this.$store.dispatch(
          "menu/setStatusCloseMenu",
          !this.$store.state.menu.is_close_menu
        );
      }
    },
    OptionEF() {
      this.is_expanded = true;
      localStorage.setItem("is_expanded", true);
      if (this.is_expanded) {
        this.isOptionEF = !this.isOptionEF;
        this.$store.dispatch("menu/setIsOptionEf", this.isOptionSetting);
        this.$store.dispatch(
          "menu/setStatusCloseMenu",
          !this.$store.state.menu.is_close_menu
        );
      }
    },
    // async modalChangeProfile() {
    //   if (
    //     this.$store.state.admin_type == 1 &&
    //     this.$store.state.setup_type != 0
    //   ) {
    //     await this.getMainCompany();
    //     this.$bvModal.show("sidebar-form-organization");
    //   }
    // },
    async getMainCompany() {
      this.isLoadingMenu = false;
      const url = `${apiURL}/member`;

      try {
        const main_company = await httpClient.get(url);
        for (let key in main_company.data.page_permission) {
          if (main_company.data.page_permission[key].access === false) {
            this.showNavigate = false;
          }
        }

        const handleCompletedStep = main_company.data.main_company.is_navigation_opened;

        // force password
        if (this.profile && this.profile.is_force_password_reset) {
          this.openModalChangePassword();
        } else {
          // show steps for use
          if (!handleCompletedStep && this.showNavigate) {
            this.$bvModal.show("modal-steps-for-use");
          }
        }

        this.primaryMainCompany = main_company.data.user
          ? main_company.data.user.primary_main_company
          : null;
        this.main_company = main_company.data;
        // console.log(this.main_company)
        this.$store.commit("setMainCompany", this.main_company);

        this.$store.dispatch("setCompanyId", main_company.data.main_company_id);
        this.$store.dispatch("setCompanyName", main_company.data.main_company.name_th);
        if (main_company.data.main_company_role) {
          this.$store.dispatch("setRoleId", main_company.data.main_company_role.id);
          this.$store.dispatch(
            "setRoleAction",
            main_company.data.main_company_role.group_roles
          );
        }

        if (main_company.data.main_company.file) {
          this.$store.dispatch(
            "setCompanyImage",
            main_company.data.main_company && main_company.data.main_company.file
              ? main_company.data.main_company.file.file_url
              : ""
          );
        } else {
          this.$store.dispatch("setCompanyImage", null);
        }

        this.$store.dispatch("setAdminType", main_company.data.type);
        this.$store.dispatch(
          "setUserType",
          main_company.data && main_company.data.user ? main_company.data.user.type : null
        );
        this.$store.dispatch("setUpType", main_company.data.main_company.set_up_type);
        this.$store.dispatch("setAuditorType", main_company.data.auditor_type);
        this.$store.dispatch("setStatusActive", main_company.data.status);
        await this.$store.dispatch(
          "setPermissionLogin",
          main_company.data.page_permission
        );
        this.isLoadingMenu = true;
        // this.permissions = this.main_company.page_permission
        // console.log('this.$store.state.permissions',this.permissions)
      } catch (err) {
        this.$toast.error({
          component: "styled-toast",
          props: {
            type: "error",
            message: err.response.data.message,
          },
        });
      }
    },
    closeModalProfileCompany() {
      this.$bvModal.hide("modal-change-profile-company");
    },
    async uploadImage(data) {
      this.formUpload = data;
      if (data.files) {
        const headers = {
          Accept: "application/json",
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${this.$store.state.token}`,
          "file-path": "company",
        };

        const url = `${apiURL}/file`;
        let formData = new FormData();
        formData.append("file", data.files);
        try {
          const uploadImage = await httpClient.post(url, formData, {
            headers: headers,
          });
          this.responseDataUpload = await uploadImage.data;
          await this.editProfile();
        } catch (err) {
          this.$toast.error({
            component: "styled-toast",
            props: {
              type: "error",
              message: err.response.data.message,
            },
          });
        }
      } else {
        this.editProfile();
      }
    },
    async editProfile() {
      const url = `${apiURL}/main_company`;
      const _data = {
        name_th: this.formUpload.company_th,
        name_en: this.formUpload.company,
        latitude: this.formUpload.latitude.toString(),
        longitude: this.formUpload.longitude.toString(),
        dashboard_setting: {
          start_date: moment(this.formUpload.startDate).format(
            "YYYY-MM-DDT00:00:00+07:00"
          ),
          end_date: moment(this.formUpload.endDate).format("YYYY-MM-DDT23:59:59+07:00"),
        },
        location_name: this.formUpload.location,
        file_id: this.responseDataUpload ? this.responseDataUpload.id : null,
        start_input_date: this.formUpload.start_input_date
          ? moment(this.formUpload.start_input_date).format()
          : null,
      };

      try {
        const updateProfile = await httpClient.put(url, _data);
        // console.log("updateProfile", updateProfile);
        if (updateProfile.status == 200) {
          this.$bvModal.hide("sidebar-form-organization");
          this.$toast.success({
            component: "styled-toast",
            props: {
              type: "success",
              message: this.$t("TXT_SAVE_DONE"),
            },
          });
          // this.getCompanyList();
        }
      } catch (err) {
        this.$bvModal.hide("modal-form-organization");
        this.$toast.error({
          component: "styled-toast",
          props: {
            type: "error",
            message: err.response.data.message,
          },
        });
      }
      this.getMainCompany();
    },
    approveMenu() {
      this.isApprove = !this.isApprove;
      this.is_expanded = true;
      localStorage.setItem("is_expanded", this.is_expanded);
    },
    async setLangLogout() {
      const url = `${apiURL}/user/language`;

      const _data = {
        language: "en",
      };

      try {
        await httpClient.put(url, _data);
      } catch (err) {
        this.$toast.error({
          component: "styled-toast",
          props: {
            type: "error",
            message: err.response.data.message,
          },
        });
      }
    },

    openSettingProfile() {
      this.user_data = this.main_company;
      this.$bvModal.show("modal-form-profile");
    },
    openModalChangePassword() {
      this.$bvModal.show("modal-form-password");
    },
    resetProfile() {
      this.user_data = null;
    },
    isDropdown() {
      if (this.countDropdownCompany > 0) {
        this.is_dropdown = !this.is_dropdown;
      }
    },
    toCompanyPage() {
      if (this.$route.fullPath != "/organization-setting/organization") {
        this.$router.push("/organization-setting/organization");
      }
    },
    toSpecialVariable() {
      if (this.$route.fullPath != "/special-variable") {
        this.$router.push("/special-variable");
      }
    },
    toUserMember() {
      if (this.$route.fullPath != "/manage-user-accounts/manage-users") {
        this.$router.push("/manage-user-accounts/manage-users");
      }
    },
    toActivityList() {
      if (this.$route.fullPath != "/manage-activities") {
        this.$router.push("/manage-activities");
      }
    },
    isBgActiveSubmenuSetting() {
      if (!this.is_expanded) {
        if (
          this.$route.fullPath == "/organization-setting/organization" ||
          this.$route.fullPath == "/organization-setting/branch" ||
          this.$route.fullPath == "/organization-setting/building" ||
          this.$route.fullPath == "/organization-setting/department" ||
          this.$route.fullPath == "/organization-setting/setting-fiscalyear" ||
          this.$route.fullPath == "/manage-user-accounts/special-variable" ||
          this.$route.fullPath == "/manage-user-accounts/manage-users" ||
          this.$route.fullPath == "/manage-activities" ||
          this.$route.fullPath == "/manage-user-accounts/manage-role-permission" ||
          this.$route.fullPath == "/manage-user-accounts/assign-task" ||
          this.$route.fullPath == "/manage-user-accounts/manage-auditors"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isActiveSubmenuEF() {
      if (!this.is_expanded) {
        if (
          this.$route.fullPath == "/mapping-emission-factor" ||
          this.$route.fullPath == "/custom-emission-factor"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isActiveTooltipMenuOrganization() {
      if (!this.is_expanded) {
        if (
          this.$route.fullPath == "/organization-setting/organization" ||
          this.$route.fullPath == "/organization-setting/branch" ||
          this.$route.fullPath == "/organization-setting/building" ||
          this.$route.fullPath == "/organization-setting/department" ||
          this.$route.fullPath == "/organization-setting/setting-fiscalyear"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    toCustomEf() {
      if (this.$route.fullPath != "/custom-emission-factor") {
        this.$router.push("/custom-emission-factor");
      }
    },
    toMappingEf() {
      if (this.$route.fullPath != "/mapping-emission-factor") {
        this.$router.push("/mapping-emission-factor");
      }
    },
    async logoutImprecit() {
      // this.generateState();
      const url = `${process.env.VUE_APP_SETLINK_URL}/session/logout?id_token_hint=${this.$store.state.oauth_token}&post_logout_redirect_uri=${process.env.VUE_APP_URL}`;
      const header = {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.$store.state.oauth_token}`,
      };
      try {
        const logoutImprecit = await axios.get(url, { headers: header });
        if (logoutImprecit.status == 200) {
          this.$toast.success({
            component: "styled-toast",
            props: {
              type: "success",
              message: "Logout Imprecit Success",
            },
          });
        }
      } catch (err) {
        this.$toast.error({
          component: "styled-toast",
          props: {
            type: "error",
            message: err,
          },
        });
      }
    },
    async refreshTokenOauth() {
      const _data = {
        client_id: "SETLink",
        client_secret: "password2",
        grant_type: "refresh_token",
        refresh_token: this.$store.state.oauth_refresh_token,
      };

      const header = {
        "Content-type": "application/x-www-form-urlencoded",
      };
      try {
        const loginSet = await axios.post(
          `${process.env.VUE_APP_SETLINK_URL}/authen/oauth/token`,
          _data,
          { headers: header }
        );

        if (loginSet.status == 200) {
          this.$store.dispatch("setoAuthToken", loginSet.data.access_token);
          this.$store.dispatch("setoAuthRefreshToken", loginSet.data.refresh_token);
          this.$toast.success({
            component: "styled-toast",
            props: {
              type: "success",
              message: "Login successful",
            },
          });
        }
      } catch (error) {
        this.$toast.error({
          component: "styled-toast",
          props: {
            type: "error",
            message: error.message,
          },
        });
      }
    },
    async logoutImprecit2() {
      // this.generateState();
      const _data = {
        id_token_hint: this.$store.state.oauth_token,
        post_logout_redirect_uri: process.env.VUE_APP_URL,
      };
      const url = `${process.env.VUE_APP_SET_TOKEN_END_POINT}`;
      const header = {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.$store.state.oauth_token}`,
      };
      try {
        const logoutImprecit = await axios.post(url, _data, {
          headers: header,
        });
        if (logoutImprecit.status == 200) {
          this.$toast.success({
            component: "styled-toast",
            props: {
              type: "success",
              message: "Logout Imprecit Success",
            },
          });
        }
      } catch (err) {
        this.$toast.error({
          component: "styled-toast",
          props: {
            type: "error",
            message: err,
          },
        });
      }
    },
    submitFunction() {
      if (this.modal_warning_id == "modal-logout") {
        this.logout();
      } else if (this.modal_warning_id == "modal-warning-double-login") {
        this.$store.commit("setToken", "");
        this.$store.commit("setRoleAction", null);

        if (this.$store.state.oauth_token) {
          this.$store.commit("setoAuthToken", null);
          this.$store.commit("setoAuthRefreshToken", null);
          let logoutSession = window.open(
            `${process.env.VUE_APP_SETLINK_URL}/authen/logout`,
            "_blank"
          );
          const myTimeout = setTimeout(() => {
            logoutSession.close();
            clearTimeout(myTimeout);
          }, 300);

          const myTimeout2 = setTimeout(() => {
            this.$router.go();
            clearTimeout(myTimeout2);
          }, 500);
        } else {
          this.$router.go();
        }
      }
    },
    async modalWarningDoubleLogin() {
      if (this.$store.state && this.$store.state.count_double_login == 1) {
        this.modal_warning_id = "modal-warning-double-login";
        this.modal_warning_title = this.$t("TXT_HEADER_WARNING_DOUBLE_LOGIN");
        this.modal_warning_content = this.$t("TXT_DETAIL_WARNING_DOUBLE_LOGIN");
        this.modal_warning_btn_name = this.$t("BTN_CLOSE");
        this.$nextTick(() => {
          this.$bvModal.show("modal-warning-double-login");
        });
      }
    },
    // async logoutAuthen() {
    //   // const header = {
    //   //     "Content-type": "application/x-www-form-urlencoded",
    //   //   };

    //   // const url = `${process.env.VUE_APP_SETLINK_URL}/authen/oauth/revoke`;
    //   // const header = {
    //   //   "Content-type": "application/json",
    //   // };
    //   // const _data = {
    //   //   client_id: "SETLink",
    //   //   client_secret: "password2",
    //   //   token:
    //   //     `${this.$store.state.oauth_token}`
    //   // };

    //   // try {
    //   //   const logoutOauth = await axios.post(url, _data, { headers: header });
    //   //   if (logoutOauth.status == 200) {
    //   //     this.$toast.success("Logout Success");
    //   //   }
    //   // } catch (err) {
    //   //   this.$toast.success({
    //   //     component: "styled-toast",
    //   //     props: {
    //   //       type: "success",
    //   //       message: err,
    //   //     },
    //   //   });
    //   // }
    //   // const url = "https://oauth2.googleapis.com/revoke"
    //   // const _data = {
    //   //   client_id: "SETLink",
    //   //   client_secret: "password2",
    //   //   token: this.$store.state.oauth_token,
    //   // };

    //   try {
    //     const logoutOAuth = await axios.post(
    //       "https://oauth2.googleapis.com/revoke?token=" +
    //         "Bearer eyJraWQiOiI2YThmYjJlMS01Zjc1LTRmM2EtYTQyYS1mMzAwZmM2NWEyOWEiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJtYW51dHRoYS5tIiwiaXNzIjoiaHR0cHM6Ly9zc291YXQuc2V0cG9ydGFsLnNldC5vci50aC9hdXRoZW4iLCJOX0xPR0lOIjoibWFudXR0aGEubSIsImF1ZCI6IlNFVExpbmsiLCJDT0RFIjoiMCIsIm5iZiI6MTcxOTU3MDA2NSwiSV9VU0VSIjoxNTgyMjcwNywiUk9MRVMiOlsiTFNUX0NSRUFUT1IiXSwic2NvcGUiOlsib3BlbmlkIl0sIlRJTUVTVEFNUCI6MTcxOTU3MDA2NSwiZXhwIjoxNzE5NTg0NDY1LCJpYXQiOjE3MTk1NzAwNjV9.a_9wpvSWOu0i8XeGe09qjINW3WCAVMcwYSTeMS-TVAgDVPqtfvK812pBFZj4HxwN4tECcDzB3k44hFpH2hnYZDQhsyo-393r0iw9_FtJ7Toch-qrF4FbaV0xL8LGdHWwEnIB3rCVjyfz2PQiomMhWemOvB9zF6lBD-UyhU1oYq2xhN7YHtKV1QZUhQIlVjoAaqpv09GkfSx46kMjzlitVxC26npg9KGOIhNRwcir2ZKykQwv6-c1YXu7nNMVtxJszgUoj5Exl5CRvTyIwB2UKNqvCxcXqO7ZkzAip94yDuN0Jbn5GV8HhgXlmCVan7myxAQ0pnR6rKT0U-5YSWb5oqiXE6H_aIFYThDsE0O9ndsafktXTTFgzx8-TseKnZCNWzDVp7N07W8_eCp7ciR-NlkUjue9k7vU4kJ574lNPqe1rHEGK32q7Y6I1xbcViEFkznmezwqJIqnRXyTojckZCNQGm0TYA6M50uDyz8nqEF96ThUtZcYfZOA1N5bKgNEAxP9SRx3NwI0yRMp64E0OZlfaWVhhKTc2HtI_VLwLugkKHxefYLXM5zJiiWjJQp4RDx0RdMYLhkOFTcMWTe_GbVfm60uQ5M5yj5Hq_B8d0MBf-sp7BQU-cb13oDAc_vzWYbFjXxnZoMMZtmWTh9_vG1DlcDvpJXGAEp8Q2APHW4",
    //       {
    //         headers: {
    //           "Content-type": "application/x-www-form-urlencoded",
    //         },
    //       }
    //     );
    //     if (logoutOAuth.status == 200) {
    //       this.$toast.success("Logout Success");
    //     }
    //   } catch (err) {
    //     this.$toast.success({
    //       component: "styled-toast",
    //       props: {
    //         type: "success",
    //         message: "Logout Failed",
    //       },
    //     });
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
.header-hover:hover {
  background-color: var(--tretiary-orange_10) !important;
}

.footer-area {
  margin: 0 -1rem;
  background: transparent;
}

.select-box {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  // font-family: "Open Sans", "Helvetica Neue", "Segoe UI", "Calibri", "Arial",
  //   sans-serif;
  font-size: 18px;
  color: #fff;
  .is_dropdown {
    position: relative;
    // box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
    cursor: pointer;
    outline: none;

    &:focus {
      & + .select-box__list {
        opacity: 1;

        animation-name: none;

        .select-box__option {
          cursor: pointer;
        }
      }

      .select-box__icon {
        transform: translateY(-50%) rotate(180deg);
        transition: 0.2s ease;
      }
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 20px;
    opacity: 0.5;
    transition: 0.2s ease;
  }

  &__value {
    display: flex;
  }

  &__input {
    display: none;

    &:checked + .select-box__input-text {
      display: block;
    }
  }

  &__input-text {
    display: none;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #353f4f;
  }

  &__list {
    position: absolute;
    width: 100%;
    padding: 0;
    list-style: none;
    opacity: 0;
    margin-top: 1rem;

    animation-name: HideList;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: step-start;
    box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
  }

  &__option {
    display: block;
    padding: 15px;
    background-color: #353f4f;

    &:hover,
    &:focus {
      color: #fff;
      background: #414d5f;
    }
  }
  .selected {
    color: #fff;
    background: #414d5f;
  }
}

@keyframes HideList {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */

.dropdown-content-footer {
  display: none;
  position: absolute;
  background-color: var(--white);
  min-width: 160px;
  width: max-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 30;
  bottom: -12px;
  left: 52px;
  /* Here I have set the width (minus padding) of the dropdown-content */
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--white);
  min-width: 160px;
  width: max-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 30;
  top: -16px;
  left: 53px;
  border-radius: 8px;
  /* Here I have set the width (minus padding) of the dropdown-content */
}

/* Links inside the dropdown */

.dropdown-content a,
.dropdown-content-footer a {
  color: var(--black);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  /* background-color: var(--white); */
}

/* Change color of dropdown links on hover */

/* Show the dropdown menu on hover */

.dropdown:hover .dropdown-content,
.dropdown:hover .dropdown-content-footer {
  display: block;
  background-color: var(--white);
}

/* Change the background color of the dropdown button when the dropdown content is shown */

.dropdown:hover .dropbtn {
  background-color: #d60707;
}

.bg-active-icon-submenu {
  background-color: var(--active);
}

.bg-active-icon-submenu-tooltip {
  background-color: var(--active);
  border-right: 5px solid var(--primary-yellow);
}

.bg-active-icon-submenu-tooltip:hover {
  background-color: var(--hover);
  border-right: 5px solid var(--primary-yellow);
}

.bg-inactive-icon-submenu-tooltip {
  background-color: var(--white);
}

.bg-inactive-icon-submenu-tooltip:hover {
  background-color: var(--hover);
}

.active-selected-box {
  background-color: var(--primary-yellow);
  border-radius: 4px;
  width: 4px;
  height: 18px;
  align-self: center;
  margin-left: 10px;
}
</style>
