import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "@/store/"
import en from "@/locales/en.json";
import th from "@/locales/th.json";
import vi from "@/locales/vi.json";
import Cookies from "js-cookie";
Vue.use(VueI18n);

const messages = {
    en: en.en,
    th: th.th,
    vi: vi.vi,
};
const initialLocale = store?.state?.selectLang?.lang || Cookies.get('user_lang');
const i18n = new VueI18n({
    locale: initialLocale,
    messages,
});

export default i18n;