<template>
  <div>
    <b-modal
      id="modal-confirm-change-password"
      ok-title="ยืนยัน"
      cancel-title="ย้อนกลับ"
      hide-header
      centered
    >
      <!-- <div class="d-flex justify-content-end">
        <i
          class="fa-solid fa-xmark fs-5 cursor-pointer"
          @click="closeModal"
        ></i>
      </div> -->
      <div class="p-2">
        <div class="text-center">
          <img src="@/assets/get-started/fillin/warning-icon.svg" alt="" />
          <div class="my-3 fw-bold text-bgreen-300" style="font-size: 24px">
            {{ header }}
          </div>
          <div class="my-3 text-bgreen-300 opacity-50" style="font-size: 20px">
            {{ text }}
            <span style="color: var(--green-600)">{{ textColor }}</span>
          </div>
        </div>
      </div>
      <template #modal-footer="{}">
        <div class="d-flex justify-content-center w-100">
          <button class="btn w-50 py-2 px-5 me-3" @click="closeModal">
            {{ $t("BTN_RETURN") }}
          </button>
          <button class="btn button-green w-50 px-5" @click="onconfirm">
            {{ $t("BTN_CONFIRM") }}
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: ["header", "text", "textColor"],
  data() {
    return {};
  },
  methods: {
    onconfirm() {
      this.$emit("onConfirm");
      this.$bvModal.hide("modal-confirm-change-password");
    },
    closeModal() {
      this.$bvModal.hide("modal-confirm-change-password");
    },
  },
};
</script>
