import axios from 'axios';
import store from '@/store';
import router from "@/router";
import Cookies from "js-cookie";
const apiInstance = axios.create();

apiInstance.interceptors.request.use(async (config)=> {
    // let imgPath = config.headers.test ? config.headers.filePath : null
    const param = await config.params
    config.headers = {
        Accept: "application/json",
        "Content-type": "application/json",
        Authorization: `Bearer ${store.state.token}`,
        Cookie : Cookies.get('session_id')
        // "file-path" : imgPath
    },
    config.params = param

    return config;
});

apiInstance.interceptors.response.use(res => {
    // Important: response interceptors **must** return the response.
    // this.$bvModal.show("modal-alert-double-login");
    if(res.headers && res.headers['set-cookie']) {
        // console.log('res.headers',res.headers)
        Cookies.set("session_id", res.headers['set-cookie'], { expires: 1 });
    }
    return res;
},err => {
    if(err.response.status == 409) {
        if(store.state && store.state.count_double_login == 0) {
            store.commit("setCountDoubleLogin", 1);
            router.go();
        }
        
        // store.commit("setToken", "");
        // store.commit("setRoleAction", null);
    } else {
        if(err.response.status != 401 && err.response.status != 409) { 
            return Promise.reject(err);
        } else {
            store.commit("setToken", "");
            store.commit("setRoleAction", null);
            router.go();
        }
    }
    
    

});
export const httpClient = apiInstance