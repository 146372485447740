<template>
  <styled-modal
    :title="titleModal"
    id="modal-steps-for-use"
    @on-shown="getUserMember"
    @on-hidden="closeModal"
  >
    <div class="p-2 pt-0">
      <div>
        <typo type="h4_web" class="text-white header-steps" style="padding-left: 16px">
          {{ $t("TXT_FOLLOW_STEPS") }}
        </typo>
        <div class="p-3 body-steps">
          <div class="p-3 mb-3 detail-steps">
            <!-- <div id="process-flow">
              <div class="step" style="background-color: coral">Ads fcfs</div>
              <div class="step" style="background-color: lightblue">
                Bwdefw dfsw sdced edew dsfdwdsfcwsdc daefedfedw
              </div>
              <div class="step" style="background-color: khaki">C</div>
              <div class="step" style="background-color: pink">D</div>
              <div class="step" style="background-color: lightgrey">E</div>
              <div class="step" style="background-color: lightgreen">F</div>
            </div> -->
            <div id="process-flow">
              <div class="step" v-for="step in steps" :key="step.id">
                <div>
                  <span
                    class="line"
                    :style="`width: ${step.step == 5 ? '11vw' : '15vw'}`"
                  />
                  <span v-if="step.is_completed" class="step-checked">
                    <check-step-icon />
                  </span>
                  <span v-else class="step-number">
                    {{ step.step }}
                  </span>

                  <typo type="bold_web" class="step-text">{{ step.title }}</typo>
                  <typo class="step-description" style="white-space: pre-line">
                    {{ step.content }}
                  </typo>
                </div>
                <div>
                  <styled-button
                    type="link"
                    style="color: var(--accessible-blue)"
                    @on-click="openLink(step.link)"
                    class="mb-2"
                  >
                    <span style="text-decoration: underline">
                      {{ $t("TXT_INSTRUCTIONAL_VIDEO") }}
                    </span>
                  </styled-button>
                  <styled-button
                    type="link"
                    style="color: var(--accessible-blue)"
                    @on-click="routerPush(step.route)"
                  >
                    <span style="text-decoration: underline">
                      {{
                        $t("TXT_GOTO_SETTING", {
                          menuName:
                            step.step != 4 ? step.menuName.toLowerCase() : step.menuName,
                        })
                      }}
                    </span>
                    <div>
                      <next-icon color="var(--accessible-blue)" />
                    </div>
                  </styled-button>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="d-flex">
            <typo type="body_web" class="me-1">คู่มือการใช้งาน</typo>
            <img
              src="@/assets/external-link.svg"
              alt=""
              class="cursor-pointer"
            />
          </div> -->
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <div class="d-flex gap-2 justify-content-end">
        <styled-button type="secondary" @on-click="closeModal" style="width: 161px">
          {{ $t("BTN_CLOSE") }}
        </styled-button>
      </div>
    </template>
  </styled-modal>
</template>

<script>
import StyledModal from "@/components/Modal/StyledModal.vue";
import { httpClient } from "@/services/axiosInstance.js";
import { apiURL } from "@/configs/serverConfig";

export default {
  props: ["visible"],
  components: {
    StyledModal,
  },
  computed: {
    titleModal() {
      return this.$t("TXT_STEP_FOR_USE");
    },
  },
  data() {
    return {
      steps: [
        {
          step: 1,
          title: this.$t("BTN_SETTING_ORGANIZATION"),
          content: this.$t("TXT_STEP1_CONTENT"),
          route: "/organization",
          is_completed: false,
          link: "https://youtu.be/SLyZn_XqaUg",
          menuName: this.$t("TXT_SETTING"),
        },
        {
          step: 2,
          title: this.$t("BTN_CREATE_ACTIVITY"),
          content: this.$t("TXT_STEP2_CONTENT"),
          route: "/manage-activities",
          is_completed: false,
          link: "https://youtu.be/uRwJge8SaWU",
          menuName: this.$t("LB_ACTIVITY"),
        },
        {
          step: 3,
          title: this.$t("TXT_CREATE_ACTIVITY_DATA"),
          content: this.$t("TXT_STEP3_CONTENT"),
          route: "/measurement-list",
          is_completed: false,
          link: "https://youtu.be/TbOhWupX5OA",
          menuName: this.$t("TXT_FILLOUT"),
        },
        {
          step: 4,
          title: `${this.$t("TXT_SURVEY")} Emission Factor(EF)`,
          content: this.$t("TXT_STEP4_CONTENT"),
          route: "/mapping-emission-factor",
          is_completed: false,
          link: "https://youtu.be/MS1WnEI6ECU",
          menuName: this.$t("TXT_EF"),
        },
        {
          step: 5,
          title: this.$t("LB_VERIFY_INFORMATION"),
          content: this.$t("TXT_STEP5_CONTENT"),
          route: "/verify-information",
          is_completed: false,
          link: "https://youtu.be/J0tIl3RPmVs",
          menuName: this.$t("TXT_VERIFY"),
        },
      ],
      is_navigation_opened: false,
    };
  },

  created() {
    this.getUserMember();
  },
  methods: {
    routerPush(route) {
      if (this.$route.fullPath !== route) {
        this.$router.push(route);
      } else {
        this.$bvModal.hide("modal-steps-for-use");
      }
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    async getUserMember() {
      try {
        const res = await httpClient.get(`${apiURL}/member`);
        const navigations = res.data.main_company.navigations;
        this.is_navigation_opened = res.data.main_company.is_navigation_opened;

        this.steps.forEach((step) => {
          const matchedNav = navigations.find((nav) => nav.step === step.step);
          if (matchedNav) {
            step.is_completed = matchedNav.is_completed;
          }
        });
      } catch (e) {
        this.$toast.error({
          component: "styled-toast",
          props: {
            type: "error",
            message: e.response.data.message,
          },
        });
      }
    },
    async closeModal() {
      if (this.is_navigation_opened) {
        this.$bvModal.hide("modal-steps-for-use");
      } else {
        const param = {
          is_navigation_opened: true,
        };
        try {
          const res = await httpClient.put(`${apiURL}/main_company`, param);
          if (res.status == 200) {
            this.$bvModal.hide("modal-steps-for-use");
          }
        } catch (e) {
          this.$toast.error({
            component: "styled-toast",
            props: {
              type: "error",
              message: e.response.data.message,
            },
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scope>
#process-flow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  .step {
    padding: 10px;
    flex-basis: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.header-steps {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #8a8a8a;
  padding: 0.5rem 0;
}
.body-steps {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #ebebeb;
}
.detail-steps {
  border-radius: 8px;
  background-color: #d1d1d1;
}
// .process-flow {
//   display: flex;
//   flex-direction: row;
//   position: relative;
//   justify-content: space-between;
//   align-items: flex-start;
//   // font-family: Arial, sans-serif;
// }

@media (max-width: 800px) {
  #process-flow {
    flex-direction: column;
  }
  .line {
    display: none !important;
  }
}

// .step {
//   padding: 10px;
//   flex-basis: 20%;
// }

.step-number {
  position: relative;
  color: var(--primary-black);
  background: var(--primary-white);
  width: 34px;
  height: 34px;
  padding: 6px 13px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
}

.step-checked {
  position: relative;
  color: var(--primary-black);
  background: var(--gradient-yellow);
  width: 34px;
  height: 34px;
  padding: 5px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
}

.line {
  position: absolute;
  /* left: 23px; */
  display: inline-block;

  height: 4px;
  top: 25px;
  background-color: white;
  margin-left: 10px;
  /* margin: 0 5px; */
}

.line-checked {
  background: var(--gradient-yellow);
}

.step-text {
  margin: 10px 0 5px;
  font-weight: bold;
  color: var(--primary-black);
}

.step-description {
  margin: 0;
  font-size: 14px;
  line-height: 1.4;
  color: var(--primary-black);
  text-align: left;
  padding-bottom: 1rem;
}
</style>
