
// import store from "@/store";
import i18n from '@/js/i18n.js';

export default {
    namespaced: true,
    state: {
        verify_agency_company: [],
        dropdown_filter_scope : [],
        agency_company_name : []
    },
    mutations: {
        setCompanyDropdownScope(state, dropdown) {
            state.dropdown_filter_scope = dropdown;
        },
        setDataAgency(state, companies) {
            state.verify_agency_company = companies;
        },
        setAgencyCompanyNameFilter(state, company_name) {
            state.agency_company_name = company_name;
        },
    },
    actions: {
        setScopeAgencyDropdown({ commit }) {
            const dropdown_filter = [{ id: 1, label: i18n.t("TXT_DD_SCOPE_VERIFY_1") },
                { id: 2, label: i18n.t("TXT_DD_SCOPE_VERIFY_2") },
                { id: 3, label: i18n.t("TXT_DD_SCOPE_VERIFY_3") },
                { id: 4, label: i18n.t("TXT_DD_SCOPE_VERIFY_4") },
                { id: 5, label: i18n.t("TXT_DD_SCOPE_VERIFY_5") },
                { id: 6, label: i18n.t("TXT_DD_SCOPE_VERIFY_6") },
                { id: 7, label: i18n.t("TXT_DD_SCOPE_VERIFY_7") },
                { id: 8, label: i18n.t("TXT_DD_SCOPE_VERIFY_8") },
                { id: 9, label: i18n.t("TXT_DD_SCOPE_VERIFY_9") },
                { id: 10, label: i18n.t("TXT_DD_SCOPE_VERIFY_10") },
                { id: 11, label: i18n.t("TXT_DD_SCOPE_VERIFY_11") },
                { id: 12, label: i18n.t("TXT_DD_SCOPE_VERIFY_12") },
                { id: 13, label: i18n.t("TXT_DD_SCOPE_VERIFY_13") },
            ]
            commit('setCompanyDropdownScope', dropdown_filter);
        },
        setDataAgency({commit}) {
            const data_agency = [
                  {
                    id: 1,
                    topic: i18n.t("TXT_COMPANY_NAME_1"),
                    company_detail: i18n.t("TXT_DETAIL_COMPANY_AGENCY_1"),
                    company_address: i18n.t("TXT_ADDRESS_COMPANY_AGENCY_1"),
                    company_link: null,
                    company_tel: "026171727",
                    company_email: "mattana@masci.or.th",
                    register_date: "02/12/2564",
                    expire_date: "01/12/2567",
                    detail_scope_verification: [
                      { scope_id: 3, name: i18n.t("TXT_COMPANY_1_SCOPE_1") },
                      { scope_id: 8, name: i18n.t("TXT_COMPANY_1_SCOPE_2") },
                      { scope_id: 4, name: i18n.t("TXT_COMPANY_1_SCOPE_3") },
                      { scope_id: 6, name: i18n.t("TXT_COMPANY_1_SCOPE_4") },
                      { scope_id: 9, name: i18n.t("TXT_COMPANY_1_SCOPE_5") },
                      { scope_id: 10, name: i18n.t("TXT_COMPANY_1_SCOPE_6") },
                      { scope_id: 12, name: i18n.t("TXT_COMPANY_1_SCOPE_7") },
                      { scope_id: 2, name: i18n.t("TXT_COMPANY_1_SCOPE_8") },
                      { scope_id: 7, name: i18n.t("TXT_COMPANY_1_SCOPE_9") },
                      { scope_id: 11, name: i18n.t("TXT_COMPANY_1_SCOPE_10") },
                      { scope_id: 13, name: i18n.t("TXT_COMPANY_1_SCOPE_11") },
                    ],
                  },
                  {
                    id: 2,
                    topic: i18n.t("TXT_COMPANY_NAME_2"),
                    company_detail: i18n.t("TXT_DETAIL_COMPANY_AGENCY_2"),
                    company_address: i18n.t("TXT_ADDRESS_COMPANY_AGENCY_2"),
                    company_link: "http://www.sgs.co.th",
                    company_tel: `026781813 ${i18n.t(
                      "TXT_EXT_PHONE"
                    )} 1051, 0854837422`,
                    company_email: "nattarin.thunsiri@sgs.com",
                    register_date: "02/12/2564",
                    expire_date: "01/12/2567",
                    detail_scope_verification: [
                      { scope_id: 3, name: i18n.t("TXT_COMPANY_2_SCOPE_1") },
                      { scope_id: 8, name: i18n.t("TXT_COMPANY_2_SCOPE_2") },
                      { scope_id: 4, name: i18n.t("TXT_COMPANY_2_SCOPE_3") },
                      { scope_id: 6, name: i18n.t("TXT_COMPANY_2_SCOPE_4") },
                      { scope_id: 9, name: i18n.t("TXT_COMPANY_2_SCOPE_5") },
                      { scope_id: 10, name: i18n.t("TXT_COMPANY_2_SCOPE_6") },
                      { scope_id: 12, name: i18n.t("TXT_COMPANY_2_SCOPE_7") },
                      { scope_id: 2, name: i18n.t("TXT_COMPANY_2_SCOPE_8") },
                      { scope_id: 5, name: i18n.t("TXT_COMPANY_2_SCOPE_9") },
                      { scope_id: 7, name: i18n.t("TXT_COMPANY_2_SCOPE_10") },
                      { scope_id: 11, name: i18n.t("TXT_COMPANY_2_SCOPE_11") },
                      { scope_id: 1, name: i18n.t("TXT_COMPANY_2_SCOPE_12") },
                      { scope_id: 13, name: i18n.t("TXT_COMPANY_2_SCOPE_13") },
                    ],
                  },
                  {
                    id: 3,
                    topic: i18n.t("TXT_COMPANY_NAME_3"),
                    company_detail: i18n.t("TXT_DETAIL_COMPANY_AGENCY_3"),
                    company_address: i18n.t("TXT_ADDRESS_COMPANY_AGENCY_3"),
                    company_link:
                      "http://www.bureauveritas.co.th/wps/wcm/connect/bv_coth/local/home/our-services/CDM",
                    company_tel: "0632179603",
                    company_email: "saravut.suparatanachatpun@bureauveritas.com",
                    register_date: "25/01/2565",
                    expire_date: "24/01/2568",
                    detail_scope_verification: [
                      { scope_id: 3, name: i18n.t("TXT_COMPANY_3_SCOPE_1") },
                      { scope_id: 8, name: i18n.t("TXT_COMPANY_3_SCOPE_2") },
                      { scope_id: 4, name: i18n.t("TXT_COMPANY_3_SCOPE_3") },
                      { scope_id: 6, name: i18n.t("TXT_COMPANY_3_SCOPE_4") },
                      { scope_id: 9, name: i18n.t("TXT_COMPANY_3_SCOPE_5") },
                      { scope_id: 10, name: i18n.t("TXT_COMPANY_3_SCOPE_6") },
                      { scope_id: 12, name: i18n.t("TXT_COMPANY_3_SCOPE_7") },
                      { scope_id: 2, name: i18n.t("TXT_COMPANY_3_SCOPE_8") },
                      { scope_id: 5, name: i18n.t("TXT_COMPANY_3_SCOPE_9") },
                      { scope_id: 7, name: i18n.t("TXT_COMPANY_3_SCOPE_10") },
                      { scope_id: 11, name: i18n.t("TXT_COMPANY_3_SCOPE_11") },
                      { scope_id: 1, name: i18n.t("TXT_COMPANY_3_SCOPE_12") },
                      { scope_id: 13, name: i18n.t("TXT_COMPANY_3_SCOPE_13") },
                    ],
                  },
                  {
                    id: 4,
                    topic: i18n.t("TXT_COMPANY_NAME_4"),
                    company_detail: i18n.t("TXT_DETAIL_COMPANY_AGENCY_4"),
                    company_address: i18n.t("TXT_ADDRESS_COMPANY_AGENCY_4"),
                    company_link: "http://www.ecee-th.com",
                    company_tel: "0896810511, 0802867771, 0626499424",
                    company_email: "vvb@eceeth.com",
                    register_date: "25/03/2565",
                    expire_date: "24/03/2568",
                    detail_scope_verification: [
                      { scope_id: 3, name: i18n.t("TXT_COMPANY_4_SCOPE_1") },
                      { scope_id: 8, name: i18n.t("TXT_COMPANY_4_SCOPE_2") },
                      { scope_id: 4, name: i18n.t("TXT_COMPANY_4_SCOPE_3") },
                      { scope_id: 6, name: i18n.t("TXT_COMPANY_4_SCOPE_4") },
                      { scope_id: 9, name: i18n.t("TXT_COMPANY_4_SCOPE_5") },
                      { scope_id: 10, name: i18n.t("TXT_COMPANY_4_SCOPE_6") },
                      { scope_id: 12, name: i18n.t("TXT_COMPANY_4_SCOPE_7") },
                      { scope_id: 2, name: i18n.t("TXT_COMPANY_4_SCOPE_8") },
                      { scope_id: 5, name: i18n.t("TXT_COMPANY_4_SCOPE_9") },
                      { scope_id: 7, name: i18n.t("TXT_COMPANY_4_SCOPE_10") },
                      { scope_id: 11, name: i18n.t("TXT_COMPANY_4_SCOPE_11") },
                      { scope_id: 1, name: i18n.t("TXT_COMPANY_4_SCOPE_12") },
                      { scope_id: 13, name: i18n.t("TXT_COMPANY_4_SCOPE_13") },
                    ],
                  },
                  {
                    id: 5,
                    topic: i18n.t("TXT_COMPANY_NAME_5"),
                    company_detail: i18n.t("TXT_DETAIL_COMPANY_AGENCY_5"),
                    company_address: i18n.t("TXT_ADDRESS_COMPANY_AGENCY_5"),
                    company_link: "https://www.bsigroup.com/th-TH/",
                    company_tel: "02-294-4889-92",
                    company_email: "thailand.info@bsigroup.com",
                    register_date: "11/03/2567",
                    expire_date: "10/03/2570",
                    detail_scope_verification: [
                      { scope_id: 3, name: i18n.t("TXT_COMPANY_5_SCOPE_1") },
                      { scope_id: 8, name: i18n.t("TXT_COMPANY_5_SCOPE_2") },
                      { scope_id: 4, name: i18n.t("TXT_COMPANY_5_SCOPE_3") },
                      { scope_id: 6, name: i18n.t("TXT_COMPANY_5_SCOPE_4") },
                      { scope_id: 9, name: i18n.t("TXT_COMPANY_5_SCOPE_5") },
                      { scope_id: 10, name: i18n.t("TXT_COMPANY_5_SCOPE_6") },
                      { scope_id: 12, name: i18n.t("TXT_COMPANY_5_SCOPE_7") },
                      { scope_id: 2, name: i18n.t("TXT_COMPANY_5_SCOPE_8") },
                      { scope_id: 5, name: i18n.t("TXT_COMPANY_5_SCOPE_9") },
                      { scope_id: 7, name: i18n.t("TXT_COMPANY_5_SCOPE_10") },
                      { scope_id: 11, name: i18n.t("TXT_COMPANY_5_SCOPE_11") },
                      { scope_id: 1, name: i18n.t("TXT_COMPANY_5_SCOPE_12") },
                      { scope_id: 13, name: i18n.t("TXT_COMPANY_5_SCOPE_13") },
                    ],
                  },
                  {
                    id: 6,
                    topic: i18n.t("TXT_COMPANY_NAME_6"),
                    company_detail: i18n.t("TXT_DETAIL_COMPANY_AGENCY_6"),
                    company_address: i18n.t("TXT_ADDRESS_COMPANY_AGENCY_6"),
                    company_link: null,
                    company_tel: "0945455177",
                    company_email: "admin-tver@doitung.org",
                    register_date: "11/03/2567",
                    expire_date: "10/03/2570",
                    detail_scope_verification: [
                      { scope_id: 3, name: i18n.t("TXT_COMPANY_6_SCOPE_1") },
                      { scope_id: 8, name: i18n.t("TXT_COMPANY_6_SCOPE_2") },
                      { scope_id: 12, name: i18n.t("TXT_COMPANY_6_SCOPE_3") },
                      { scope_id: 11, name: i18n.t("TXT_COMPANY_6_SCOPE_4") },
                      { scope_id: 1, name: i18n.t("TXT_COMPANY_6_SCOPE_5") },
                      { scope_id: 13, name: i18n.t("TXT_COMPANY_6_SCOPE_6") },
                    ],
                  },
                  {
                    id: 7,
                    topic: i18n.t("TXT_COMPANY_NAME_7"),
                    company_detail: i18n.t("TXT_DETAIL_COMPANY_AGENCY_7"),
                    company_address: i18n.t("TXT_ADDRESS_COMPANY_AGENCY_7"),
                    company_link: null,
                    company_tel: "0650924909",
                    company_email: "contact@vgreenku.com",
                    register_date: "11/03/2567",
                    expire_date: "10/03/2570",
                    detail_scope_verification: [
                      { scope_id: 8, name: i18n.t("TXT_COMPANY_7_SCOPE_1") },
                      { scope_id: 10, name: i18n.t("TXT_COMPANY_7_SCOPE_2") },
                      { scope_id: 12, name: i18n.t("TXT_COMPANY_7_SCOPE_3") },
                      { scope_id: 7, name: i18n.t("TXT_COMPANY_7_SCOPE_4") },
                      { scope_id: 11, name: i18n.t("TXT_COMPANY_7_SCOPE_5") },
                      { scope_id: 1, name: i18n.t("TXT_COMPANY_7_SCOPE_6") },
                      { scope_id: 13, name: i18n.t("TXT_COMPANY_7_SCOPE_7") },
                    ],
                  },
                ]
                commit('setDataAgency', data_agency);
        },
    },
    getters: {
        companyData(state) {
            return state.verify_agency_company;
        }
    }
}
